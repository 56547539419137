import React, { useEffect, useRef, useState, useCallback } from "react"
import { useParams, useNavigate, NavLink } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { t } from "i18next"

import { priceLineID } from '../config.js'
import { GetHistoryData, PriceFormat, FormatDate, ShowLoader, HideLoader, getTranslatedName, DataPreloader, returnSortedCartData } from "../functions.js"
import { getRequestOptions } from '../helpers/apiHelper'
import { addReturnProducts, clearReturnProducts, addReturnSelected, removeReturnSelected, updateReturnSelectedQTY, updateReturnSelectedReason, addReason, setIsClearReturnProducts } from "../reduxToolkit/returnsSlice"
import { setErrorMessage } from '../reduxToolkit/allproduct'

import { BreadCrumbs } from "../components/BreadCrumbs"
import { SortingProductsData } from "../components/SortingProductsData"
import { OrderHistoryPopup } from "../components/OrderHistoryPopup.jsx"
import LoadingDots from "../components/LoadingDots/LoadingDots"

import './../assets/css/invoices.css'

import { ReactComponent as Refresh } from './../assets/svg/returnrefresh.svg'
import { ReactComponent as History } from './../assets/svg/returnhistory.svg'
import { ReactComponent as Sort } from './../assets/svg/returnsort.svg'
import { ReactComponent as ArrowRight } from './../assets/svg/returnarrowright.svg'
import { ReactComponent as Info } from './../assets/svg/returninfo.svg'
import defaultImage from './../assets/images/placeholder.jpg'

const DateFormat = localDate => localDate && localDate.length > 0 && localDate.split(' ')[0].replaceAll('/', '.')

export const ReturnProducts = () => {
    const { id } = useParams()
    const returnID = id

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const returnsContent = useSelector(state => state.returnsContent.returnsContent)
    const selectedContent = useSelector(state => state.returnsContent.selectedContent)
    const selectedContentIDs = useSelector(state => state.returnsContent.selectedContentIDs)
    const selectedContentQTYsMax = useSelector(state => state.returnsContent.uuidQTYs)
    const ReasonsForReturnRedux = useSelector(state => state.returnsContent.reasons)
    const isClearReturnProducts = useSelector(state => state.returnsContent.isClearReturnProducts)

    const otherInputEl = useRef(null)
    const returnPopupEl = useRef(null)

    const [step, setStep] = useState('one')
    const [currentID, setCurrentID] = useState('')
    const [currentQTY, setCurrentQTY] = useState('')
    const [currentReason, setCurrentReason] = useState('')
    const [returnData, setReturnData] = useState({})
    const [loadingStatus, setLoadingStatus] = useState('waiting')
    const [disabled, setDisabled] = useState(false)
    const [isOpenHistoryProduct, setIsOpenHistoryProduct] = useState(false)
    const [idProductForHistory, setIdProductForHistory] = useState('')
    const [isTableDataLoaded, setTableDataLoaded] = useState(true)
    const [isSortingProducts, setIsSortingProducts] = useState(false)
    const [isSortingProductsClose, setIsSortingProductsClose] = useState(false)
    const [selectSortByItem, setSelectSortByItem] = useState('')
    const [sortReturnProducts, setSortReturnProducts] = useState([])

    const ShowReturnPopup = e => {
        returnPopupEl.current.classList.add('active')
        setCurrentID(e.target.dataset.id)
        setCurrentQTY(e.target.dataset.qty)
        setCurrentReason(e.target.dataset.reason)
    }
    const SubmitReturnPopup = () => {
        dispatch(updateReturnSelectedQTY({ uuid: currentID, qty: currentQTY }))
        dispatch(updateReturnSelectedReason({ uuid: currentID, reason: currentReason }))
        HideReturnPopup()
    }
    const HideReturnPopup = () => {
        returnPopupEl.current.classList.remove('active')
        otherInputEl.current.classList.add('hidden')
    }

    const GoToStepTwo = () => selectedContentIDs.length > 0 && setStep('two')

    useEffect(() => {
        if (ReasonsForReturnRedux?.length === 0) {
            fetch(`${process.env.REACT_APP_API_DOMAIN}/return-types`, getRequestOptions())
                .then(response => response.json())
                .then(json => {
                    json.data && json.data.forEach(e => dispatch(addReason({ id: e.id, name: e.matchcode })))
                })
                .catch(error => console.error(error))
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/documents/${returnID}?include=details.item,details.item.itemDescriptions,details.item.itemKinds,details.item.itemFamily,status&realKey=true&showVat=true&source_price_id[]=${priceLineID}&warehouse_id=${localStorage.getItem('warehouse.id')}`, getRequestOptions())
            .then(response => response.json())
            .then(json => {
                json.data && setReturnData({
                    id: json.data.id,
                    idReal: json.data.id_real,
                    invoice: `${json.data.key} ${json.data.serial} - ${String(json.data.number).padStart(6, '0')}`,
                    returnDate: FormatDate(),
                    createDate: json.data.created_at,
                    processID: json.data.process_id,
                    processIDReal: json.data.process_id_real,
                    totalNet: json.data.total_net_amount,
                    totalVat: json.data.total_tax_amount,
                    totalGross: json.data.total_tax_included_amount,
                })
                json.data.details && json.data.details.data && json.data.details.data.forEach(e => {
                    dispatch(addReturnProducts({
                        id: e.item.data.id,
                        itemID: e.item.data.item_id,
                        family: e.item?.data?.itemFamily?.data?.description || 'zz',
                        kinds_type: e.item?.data?.itemKinds?.data[0]?.real_id || 5,
                        price: e.unit_price || 0,
                        unit_code: e.unit_code_name || e.unit_code || '',
                        inbox: e.coefficient_2 || e.description.includes('*') ? e.description.slice(e.description.indexOf('*') + 1, e.description.indexOf(')')) : 0,
                        productName: getTranslatedName(e.item.data) ? getTranslatedName(e.item.data) : e.description,
                        productImage: (e.item && e.item.data && e.item.data.attachments && e.item.data.attachments.data && e.item.data.attachments.data[0] && e.item.data.attachments.data[0].awsFileUrl) || defaultImage,
                        qtyincart: e.quantity,
                        uuid: e.uuid,
                    }))
                })
            })
            .catch(error => console.error(error))
            .finally(() => {
                setTableDataLoaded(false)
                HideLoader()
            })

        dispatch(setIsClearReturnProducts(true))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (isClearReturnProducts) {
            dispatch(clearReturnProducts())
        }
        // eslint-disable-next-line
    }, [])

    /* scroll direction */
    const [y, setY] = useState(window.scrollY)
    const handleNavigation = useCallback(e => {
        const window = e.currentTarget
        y > window.scrollY ? document.body.classList.add('fixed_bottom') : document.body.classList.remove('fixed_bottom')
        setY(window.scrollY)
    }, [y])

    useEffect(() => {
        setY(window.scrollY)
        window.addEventListener("scroll", handleNavigation)
        return () => {
            window.removeEventListener("scroll", handleNavigation)
        }
    }, [handleNavigation])
    /* scroll direction */

    useEffect(() => {
        if (selectSortByItem && returnsContent?.length > 0) {
            const resSort = returnSortedCartData(selectSortByItem, returnsContent)
            setSortReturnProducts(resSort)
        }
    }, [selectSortByItem, returnsContent])

    const ChangeQTYs = e => {
        let value = parseInt(e.target.value) || 0
        const maxValue = parseInt(e.target.dataset.max)
        if (value > maxValue) value = maxValue
        setCurrentQTY(value)
    }
    const ChangeQTYsPlus = e => {
        const uuid = e.target.dataset.uuid
        let value = parseInt(e.target.dataset.value) || 0
        value++
        const maxValue = parseInt(e.target.dataset.max) || selectedContentQTYsMax[uuid]
        if (value > maxValue) value = maxValue
        setCurrentQTY(value)
    }
    const ChangeQTYsMinus = e => {
        const value = parseInt(e.target.dataset.value) === 0 ? 0 : parseInt(e.target.dataset.value) - 1
        setCurrentQTY(value)
    }

    const CheckboxClick = e => {
        const uuid = e.target.dataset.uuid
        const tempObj = {
            uuid: e.target.dataset.uuid,
            id: e.target.dataset.id,
            productName: e.target.dataset.name,
            itemID: e.target.dataset.itemid,
            image: e.target.dataset.image,
            inbox: e.target.dataset.inbox,
            uni: e.target.dataset.uni,
            price: e.target.dataset.price,
            qtyReturn: selectedContentQTYsMax[uuid],
            reason: ReasonsForReturnRedux[0].id,
        }
        if (e.target.checked) {
            dispatch(addReturnSelected(tempObj))
        } else {
            dispatch(removeReturnSelected(uuid))
        }
    }

    const [otherReason, setOtherReason] = useState('')

    const ReasonChange = e => {
        let reasonName = e.target.dataset.value
        const uuid = currentID
        reasonName === '0KkzrXn5gamP2ypM' ? otherInputEl.current.classList.remove('hidden') : otherInputEl.current.classList.add('hidden')
        setCurrentReason(reasonName)
        dispatch(updateReturnSelectedReason({ uuid: uuid, reason: reasonName }))
    }
    const OtherReasonChange = e => {
        const localValue = e.target.value
        setOtherReason(localValue)
    }

    const ConfirmSubmit = () => {
        setDisabled(true)
        setLoadingStatus('loading')
        let detail_uuids = []
        let detail_quantitys = []
        let detail_reason_messages = []

        const ifError = () => {
            setDisabled(false)
            setLoadingStatus('waiting')
            dispatch(setErrorMessage(t('niki.b2b.feedback.error_return_products') + ' ' + t('niki.b2b.feedback.contact_your_manager') + '.'))
        }

        selectedContent.forEach(element => {
            if (parseInt(element.qtyReturn) > 0 && element.reason.length > 0) {
                detail_uuids.push(element.uuid)
                detail_quantitys.push(element.qtyReturn)
                detail_reason_messages.push(element.reason)
            }
        })

        const requestOptionsPost = getRequestOptions('POST', null, 'application/json')
        requestOptionsPost.body = JSON.stringify({
            document_uuid: returnID,
            detail_uuid: detail_uuids,
            detail_quantity: detail_quantitys,
            detail_reason_message: detail_reason_messages,
        })

        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/return-documents `, requestOptionsPost)
            .then(response => response.json())
            .then(json => {
                if (json.uuid) {
                    navigate(`/return-submited/${json.uuid}/`)
                } else {
                    ifError()
                }
            })
            .catch(error => {
                console.error(error)
                ifError()
            })
            .finally(() => HideLoader())
    }

    const ShowProdHistory = e => {
        setIsOpenHistoryProduct(true)
        setIdProductForHistory(e.target.dataset.id)
    }

    const showHideSortPanel = () => {
        if (isSortingProducts) {
            setIsSortingProductsClose(true)
        } else {
            setIsSortingProducts(true)
            setIsSortingProductsClose(false)
        }
    }

    const onProductClick = () => {
        dispatch(setIsClearReturnProducts(false))
        localStorage.setItem('url.back', `${window.location.pathname}`)
    }

    const returnProductContent = (e, i) => {
        return (
            <div key={`productdata${i}`} className="return_products__item">
                <label className="return_products__item__checkbox">
                    <input
                        type="checkbox"
                        onChange={CheckboxClick}
                        data-id={e.id}
                        data-uuid={e.uuid}
                        data-name={e.productName}
                        data-image={e.productImage}
                        data-itemid={e.itemID}
                        data-inbox={e.inbox}
                        data-uni={e.unit_code}
                        data-price={e.price}
                        checked={selectedContentIDs.includes(e.uuid)}
                    />
                </label>
                <NavLink to={`/productpage/${e.id}/`} onClick={onProductClick} className="return_products__item__image">
                    <img src={e.productImage} alt={e.productName} loading="lazy" />
                </NavLink>
                <div>
                    <NavLink to={`/productpage/${e.id}/`} onClick={onProductClick} className="return_products__item__link">{e.itemID} - {e.productName}</NavLink>
                    <div className="return_products__item__history">
                        {GetHistoryData(e.id).length > 0
                            ? <button type="button" data-id={e.id} onClick={ShowProdHistory}>
                                <Refresh />
                                {GetHistoryData(e.id).filter((_, index) => index === 0).map((e, i) => <span key={`prodhistory${e.id}${i}`}>{FormatDate(e.created_at)} &mdash; {e.quantity} &nbsp;</span>)}
                                {t('niki.b2b.returnproducts.units')}
                                <History />
                            </button>
                            : null
                        }
                    </div>
                </div>
                {GetHistoryData(e.id).length > 0 && isOpenHistoryProduct && e.id === idProductForHistory &&
                    <OrderHistoryPopup
                        matchcode={e.productName}
                        imgsrc={e.productImage}
                        sale_unit_code={e.unit_code}
                        qty_in_box={e.inbox}
                        item_id={e.itemID}
                        product_id={e.id}
                        setIsOpenHistoryProduct={setIsOpenHistoryProduct}
                    />
                }
            </div>
        )
    }

    return (
        <div className="content">
            <div className="breadcrumbs__wrapper">
                <BreadCrumbs itemlist={[{ 'url': '/invoices/', 'title': t('niki.b2b.menu.invoices') }]} />
                <ol className={[`breadcrumbs__steps`, step].join(' ')}>
                    <li><span>{t('niki.b2b.returnproducts.products')}</span></li>
                    <li><span>{t('niki.b2b.returnproducts.quantities')}</span></li>
                    <li></li>
                </ol>
            </div>

            <div className="return_content">
                {returnData.invoice
                    ? <h2> {t('niki.b2b.invoices.return')} {returnData.invoice}</h2>
                    : <div className={["wrapper_loading_dots", "wrapper_loading_dots_return"].join(' ')}><h2>{t('niki.b2b.invoices.return')}</h2><LoadingDots /></div>
                }
                <div className="order_reference">
                    <h2>{t('niki.b2b.returnproducts.orderreference')}</h2>
                    <div className="order_reference__table">
                        <strong>{t('niki.b2b.feedback.orderid')}</strong>
                        <span>{returnData.idReal}</span>
                    </div>
                    <div className="order_reference__table">
                        <strong>{t('niki.b2b.debts.orderdate')}</strong>
                        <span>{DateFormat(returnData.createDate)}</span>
                    </div>
                    <div className="order_reference__table">
                        <strong>{t('niki.b2b.returnproducts.date')}</strong>
                        <span>{DateFormat(returnData.returnDate)}</span>
                    </div>
                    <div className="order_reference__table">
                        <strong>{t('niki.b2b.orders.invoiceno')}</strong>
                        <span>{returnData.invoice}</span>
                    </div>
                    <div className="order_reference__table">
                        <strong>{t('niki.b2b.debts.processid')}</strong>
                        <span>{returnData.processIDReal}</span>
                    </div>
                </div>

                {step === 'one' && <div className="products_have_been_returned">{t('niki.b2b.returnproducts.select')}.</div>}
                {step === 'two' && <div className="products_have_been_returned">{t('niki.b2b.returnproducts.selectquantitiestoreturn')}.</div>}

                {step === 'one' &&
                    <div className="return_products one">
                        {isTableDataLoaded && returnsContent.length === 0
                            ? < DataPreloader qtyRows={3} />
                            : sortReturnProducts.length > 0
                                ? sortReturnProducts.map((e, i) => returnProductContent(e, i))
                                : returnsContent.map((e, i) => returnProductContent(e, i))
                        }
                    </div>
                }

                {step === 'two' &&
                    <div className="return_products two">
                        {selectedContent.map((e, i) =>
                            <div key={`selectedcontent${i}`} className="return_products__item">
                                <NavLink to={`/productpage/${e.id}/`} onClick={onProductClick} className="return_products__item__image">
                                    <img src={e.image} alt={e.productName} loading="lazy" />
                                </NavLink>
                                <div>
                                    <NavLink to={`/productpage/${e.id}/`} onClick={onProductClick} className="return_products__item__link">{e.itemID} - {e.productName}</NavLink>
                                    <div className="return_products__item__history">
                                        {GetHistoryData(e.id).length > 0
                                            ? <button type="button" data-id={e.id} onClick={ShowProdHistory}>
                                                <Refresh />
                                                {GetHistoryData(e.id).filter((_, index) => index === 0).map((e, i) => <span key={`prodhistory${e.id}${i}`}>{FormatDate(e.created_at)} &mdash; {e.quantity}</span>)}
                                                {t('niki.b2b.returnproducts.units')}
                                                <History />
                                            </button>
                                            : null
                                        }
                                    </div>
                                </div>
                                <div className="return_products__item__count">
                                    <button
                                        onClick={ShowReturnPopup}
                                        data-id={e.uuid}
                                        data-qty={e.qtyReturn}
                                        data-reason={e.reason}
                                    >
                                        <span>{e.qtyReturn}</span>
                                        <Info />
                                    </button>
                                </div>
                                {GetHistoryData(e.id).length > 0 && isOpenHistoryProduct && e.id === idProductForHistory &&
                                    <OrderHistoryPopup
                                        matchcode={e.productName}
                                        imgsrc={e.image}
                                        sale_unit_code={e.uni}
                                        qty_in_box={e.inbox}
                                        item_id={e.itemID}
                                        product_id={e.id}
                                        setIsOpenHistoryProduct={setIsOpenHistoryProduct}
                                    />
                                }
                            </div>
                        )}
                    </div>
                }

                <div className="return_popup__wrapper" ref={returnPopupEl}>
                    <div className="return_popup">
                        <div className="return_popup__header">{t('niki.b2b.returnproducts.quantityreturned')}</div>
                        <div className="return_popup__input_wrapper">
                            <button
                                className="return_popup__input_button minus"
                                data-uuid={currentID}
                                data-value={currentQTY}
                                data-max={selectedContentQTYsMax[currentID]}
                                onClick={ChangeQTYsMinus}
                            >-</button>
                            <input
                                className="return_popup__input_input"
                                value={currentQTY || 0}
                                data-max={selectedContentQTYsMax[currentID]}
                                data-uuid={currentID}
                                onChange={ChangeQTYs}
                            />
                            <button
                                className={[`return_popup__input_button plus`, selectedContentQTYsMax[currentID] === currentQTY ? `disabled` : `normal`].join(' ')}
                                data-uuid={currentID}
                                data-value={currentQTY}
                                data-max={selectedContentQTYsMax[currentID]}
                                onClick={ChangeQTYsPlus}
                            >+</button>
                        </div>
                        <hr />
                        <div className="return_popup__header">{t('niki.b2b.returnproducts.reason')}</div>
                        <ul className="return_popup__list">
                            {ReasonsForReturnRedux.map((e, i) =>
                                <li key={`reason${i}`}>
                                    <label>
                                        <input
                                            type="radio"
                                            name="reason"
                                            data-value={e.id}
                                            checked={currentReason === e.id}
                                            onChange={ReasonChange}
                                        />{e.name === "Other" ? t('niki.b2b.returnproducts.other') : e.name}
                                    </label>
                                </li>
                            )}
                        </ul>
                        <input
                            data-uuid={currentID}
                            className="form-control marginbottom20 hidden"
                            placeholder="Specify reason here"
                            onChange={OtherReasonChange}
                            value={otherReason}
                            ref={otherInputEl}
                        />
                        <div className="return_popup__buttons">
                            <button className="btn btn-info" onClick={HideReturnPopup}>{t('niki.b2b.returnproducts.close')}</button>
                            <button className="btn btn-primary" onClick={SubmitReturnPopup}>{t('niki.b2b.returnproducts.submit')}</button>
                        </div>
                    </div>
                    <div className="return_popup__overlay" onClick={HideReturnPopup}></div>
                </div>
            </div>

            <div className="return_bottom_fixed">
                <div className="return_bottom_fixed__white">
                    <div>
                        <div className="strong">{t('niki.b2b.returnproducts.skusselected')}</div>
                        <div className="strong">{t('niki.b2b.orders.totalnet')}</div>
                        <div className="strong">{t('niki.b2b.orders.totalvat')}</div>
                    </div>
                    <div className="right">
                        <div>{selectedContentIDs.length}</div>
                        <div>{PriceFormat(returnData.totalNet)}</div>
                        <div>{PriceFormat(returnData.totalVat)}</div>
                    </div>
                    <div className="return_bottom_fixed__white__last">
                        <div>{t('niki.b2b.orders.totalgros')} <span className="total_gros">{PriceFormat(returnData.totalGross)}</span></div>
                    </div>
                </div>
                {step === 'one' &&
                    <div className="return_bottom_fixed__grey">
                        <button className={isSortingProducts ? "btn btn-lightgrey" : "sorting__products_button"} disabled={returnsContent?.length === 0} onClick={showHideSortPanel}>
                            <Sort />
                        </button>
                        <button className="btn btn-primary" disabled={selectedContentIDs.length === 0} onClick={GoToStepTwo}>
                            {t('niki.b2b.returnproducts.selectquantities')}
                            <ArrowRight />
                        </button>
                    </div>
                }
                {step === 'two' &&
                    <div className="return_bottom_fixed__grey two_columns">
                        {/* <button className="return_bottom_fixed__grey__sort"><Sort /></button> */}
                        <button className="btn btn-outline btn__small_padding" onClick={() => setStep('one')}><ArrowRight className="oneeightzero" />{t('niki.b2b.cart.items')}</button>
                        <button
                            className={[`btn btn-green submit_loader`, loadingStatus, 'btn__small_padding'].join(' ')}
                            onClick={ConfirmSubmit}
                            disabled={disabled}
                        >
                            <span className="waiting">{t('niki.b2b.returnproducts.confirm')}<ArrowRight /></span>
                            <span className="loading round_spinner"></span>
                        </button>
                    </div>
                }
            </div>
            {isSortingProducts && <SortingProductsData isSortingProductsClose={isSortingProductsClose} setIsSortingProducts={setIsSortingProducts} selectSortByItem={selectSortByItem} setSelectSortByItem={setSelectSortByItem} />}
        </div>
    )
}