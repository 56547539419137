import React, { useState, useEffect } from "react"
import { NavLink, useNavigate } from 'react-router-dom'
import { t } from "i18next"
import { GetGlobalBannerData, onBannerView } from "../functions"

import { BreadCrumbs } from "../components/BreadCrumbs"
import LoadableImage from "../components/LoadableImage/LoadableImage"

import './../assets/css/marketing.css'

import { ReactComponent as PromotionalBooklets } from './../assets/svg/gridpromotionalbooklets.svg'
import { ReactComponent as ProductCatalogs } from './../assets/svg/gridproductcatalogs.svg'
import { ReactComponent as OurBrands } from './../assets/svg/gridourbrands.svg'
import { ReactComponent as Videos } from './../assets/svg/gridvideos.svg'

export const Marketing = () => {
    const navigate = useNavigate()

    const [bannersMiddle,setBannersMiddle] = useState([])

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem('banners_data'))
        data && data.forEach(e => {
           // e.matchcode === 'B2B - Marketing - Banners'
           e.content_subtype_id === 'gOkj31nzQol9e5EQ' && e.contentType?.data?.real_id === 6 && setBannersMiddle(GetGlobalBannerData(e))
        })
    }, [])

    return (
        <div className="content">
            <BreadCrumbs itemlist={[{'url':'/marketing/','title':t('niki.b2b.menu.marketing')}]} />
            <div className="marketing_content">
                <h1>{t('niki.b2b.menu.marketing')}</h1>

                <ul className="marketing_grid">
                    <li><NavLink to="/promotional-booklets/"><PromotionalBooklets />{t('niki.b2b.marketing.booklets')}</NavLink></li>
                    <li><NavLink to="/product-catalogs/"><ProductCatalogs />{t('niki.b2b.marketing.catalogs')}</NavLink></li>
                    <li><NavLink to="/brands/"><OurBrands />{t('niki.b2b.marketing.brands')}</NavLink></li>
                    <li><NavLink to="/videos/"><Videos />{t('niki.b2b.marketing.videos')}</NavLink></li>
                </ul>

            </div>
            <h2 className="center">{t('niki.b2b.marketing.discover')}</h2>
            {bannersMiddle.map((e, i) => <div onClick={() => onBannerView(e, navigate)} key={`middlebanner${i}`} className="marketing_banner">
                <LoadableImage  src={e.image} alt={e.title} height={'152px'}/>
            </div>)}
        </div>
    )
}