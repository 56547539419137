import defaultImage from './assets/images/placeholder.jpg'
import { xCompanyID, companyName, companySupplier, priceLineID, xTenant } from './config'
import { t } from "i18next"
import { getBanners, getRequestOptionsNoAuth, updateMarketingCampaignIds } from './helpers/apiHelper'

// start input phone 
import es from 'react-phone-input-2/lang/es.json'
import ru from 'react-phone-input-2/lang/ru.json'
import it from 'react-phone-input-2/lang/it.json'
import pt from 'react-phone-input-2/lang/pt.json'
import de from 'react-phone-input-2/lang/de.json'
import fr from 'react-phone-input-2/lang/fr.json'

export const LangFix = (e) => e === 'gb' ? 'en' : e

export const returnLangForFlag = (lang) => {
    return lang ? lang === 'en'
        ? 'gb' : lang
        : 'gb'
}

const lang = LangFix(localStorage.getItem('locale'))

const localLanguage = (lang) => {
    switch (lang) {
        case 'es': return es
        case 'ru': return ru
        case 'pt': return pt
        case 'de': return de
        case 'fr': return fr
        case 'it': return it
        default: { }
    }
}
export const generateLocatilization = () => localLanguage(lang)
// end input phone 

// export const PriceFormat = price => parseFloat(price).toFixed(2)
// export const PriceFormat = price => new Intl.NumberFormat(localStorage.getItem('locale'), {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(price)
// export const PriceFormat = price => new Intl.NumberFormat(localStorage.getItem('currency.code'), {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(price)

export const ShowLoader = () => document.querySelector('.loader').classList.add('active')
export const HideLoader = () => document.querySelector('.loader').classList.remove('active')

export const scrollTop = () => {
    window.scrollTo({
        top: 55,
        behavior: 'smooth'
    })
}

export const FormatDate = localDate => {
    const fullDate = localDate ? new Date(localDate) : new Date()
    return `${String(fullDate.getDate()).padStart(2, '0')}.${String(fullDate.getMonth() + 1).padStart(2, '0')}.${fullDate.getFullYear()}`
}

export const PriceFormat = (price, showCurrency = true) => {
    if (isNaN(price) || price === null || price === '') {
        return ''
    }
    let toReturn
    const thousandSeparator = localStorage.getItem('currency.thousand')
    const decimalSeparator = localStorage.getItem('currency.decimal')
    const priceStr = String(parseFloat(price).toFixed(2)).replace(',', '.')
    const parts = priceStr.split('.')
    let intPart = parts[0]
    const decimalPart = parts[1] || ''

    if (thousandSeparator && intPart.length > 3) {
        let newIntPart = ''
        for (let i = intPart.length - 1, j = 0; i >= 0; i--, j++) {
            if (j > 0 && j % 3 === 0) {
                newIntPart = thousandSeparator + newIntPart
            }
            newIntPart = intPart[i] + newIntPart
        }
        intPart = newIntPart
    }
    let formattedDecimalPart = ''
    if (decimalSeparator && decimalPart.length > 0) {
        formattedDecimalPart = decimalSeparator + decimalPart
    }
    toReturn = `${intPart}${formattedDecimalPart}`
    if (showCurrency) toReturn = `${localStorage.getItem('currency.left')}${toReturn}${localStorage.getItem('currency.right')}`
    return toReturn
}

export const CapacityString = (propsCapacity, capacity_unit_code, price) => {
    const capacity = !!propsCapacity ? propsCapacity : 1

    switch (capacity_unit_code) {
        case 'ML':
            return `${PriceFormat(price / capacity * 1000)} / ${t('niki.b2b.lt')}`
        case 'CL':
            return `${PriceFormat(price / capacity * 100)} / ${t('niki.b2b.lt')}`
        case 'LT':
            return `${PriceFormat(price / capacity)} / ${t('niki.b2b.lt')}`
        case 'GM':
            return `${PriceFormat(price / capacity * 1000)} / ${t('niki.b2b.kg')}`
        case 'KG':
            return `${PriceFormat(price / capacity)} / ${t('niki.b2b.kg')}`
        default:
            return null
    }
}

export const GetDiscountPrice = (itemDiscounts, priceline_id) => {
    let toReturn = { show: false }

    if (itemDiscounts.data && itemDiscounts.data.length > 0) {
        itemDiscounts.data.forEach(el => {
            if (el.priceline_id === priceline_id) {
                toReturn.show = true
                toReturn.price = el.unit_price
                toReturn.gross = el.tax_included_price
                toReturn.vat = el.tax_included_price - el.unit_price
                toReturn.pvp = 0
            }
        })
    }

    // if (itemDiscounts.data && itemDiscounts.data.length > 0 && itemDiscounts.data[0]) {
    //     toReturn.show = true
    //     toReturn.price = itemDiscounts.data[0].unit_price
    //     toReturn.gross = itemDiscounts.data[0].tax_included_price
    //     toReturn.vat = itemDiscounts.data[0].tax_included_price - itemDiscounts.data[0].unit_price
    //     toReturn.pvp = 0
    // }
    return toReturn
}

export const GetMainImage = (attachments, attachment_id) => {
    let imgsrc = defaultImage
    if (attachments.data) {
        attachments.data.forEach(e => {
            if (e.id === attachment_id) imgsrc = e.url
        })
    }
    return imgsrc
}
export const GetMainImageCart = (attachments, attachment_id) => {
    let imgsrc = defaultImage
    if (attachments.data) {
        attachments.data.forEach(e => {
            if (e.id === attachment_id) imgsrc = e.awsFileUrl
        })
    }
    return imgsrc
}

export const GetTotalNet = data => {
    let toReturn = 0
    if (data && data.length > 0) {
        data.forEach(e => {
            toReturn += parseFloat(e.unit_price) * parseFloat(e.qty)
        })
    }
    return toReturn
}

export const VatByCartDetails = cartDetailsData => {
    let vatReturn = 0
    if (cartDetailsData && cartDetailsData.length > 0) {
        cartDetailsData.forEach(e => {
            if (e.tax_included_price && parseFloat(e.tax_included_price) > 0 && e.unit_price && parseFloat(e.unit_price) > 0 && e.qty && parseFloat(e.qty) > 0) {
                vatReturn += (parseFloat(e.tax_included_price) - parseFloat(e.unit_price)) * parseInt(e.qty)
            }
        })
    }
    return vatReturn
}
export const NetByCartDetails = cartDetailsData => {
    let netReturn = 0
    if (cartDetailsData && cartDetailsData.length > 0) {
        cartDetailsData.forEach(e => {
            if (e.unit_price && parseFloat(e.unit_price) > 0 && e.qty && parseFloat(e.qty) > 0) {
                netReturn += parseFloat(e.unit_price) * parseInt(e.qty)
            }
        })
    }
    return netReturn
}

export const ArrayUniqe = array => [...new Set(array)]

export const RemoveHistoryLocalStorage = () => {
    for (var key in localStorage) {
        if (key.startsWith("history_")) {
            localStorage.removeItem(key)
        }
    }
}

export const GetHistoryData = productID => {
    if (productID === null || productID === '') {
        return false
    }
    let toReturn = []
    for (var key in localStorage) {
        if (key.startsWith(`history_${productID}_`)) {
            toReturn.push(JSON.parse(localStorage[key]))
        }
    }
    return toReturn
}

export const removeNonPrintableCharacters = str => {
    return str.replace(/[^\x20-\x7E]/g, '')
}

export const GetGlobalBannerData = e => {
    const toReturn = []
    const images = {}
    const links = {}
    if (e.attachments?.data) {
        e.attachments.data.forEach(el => images[el.id] = el.url)
    }

    if (e.content_url === null && e.json && e.attachments?.data) {
        const jsonData = JSON.parse(removeNonPrintableCharacters(e.json))

        jsonData.images && jsonData.images.forEach(el => {
            for (let key in el) {
                links[key] = el[key]
            }
        })
    }

    e.attachments && e.attachments.data && e.attachments.data.forEach(j => {
        j?.links && j.links.forEach(u => {
            if ((u.extension === "jpg" || u.extension === "jpeg" || u.extension === "png") && u.template === "original") {
                const tempObj = {
                    image: u.url,
                    link: links[j.id],
                    title: e.matchcode,
                    bannerId: j.id,
                }
                toReturn.push(tempObj)
            }
        })
    })

    return toReturn
}

export const GoToLogin = () => {
    localStorage.removeItem('access.token')
    localStorage.removeItem('access.token.lifetime')
    localStorage.removeItem('account.user.name')
    localStorage.removeItem('account.entity.id')
    localStorage.removeItem('account.entity.id.real')
    localStorage.removeItem('account.entity.name')
    localStorage.removeItem('account.company')
    localStorage.removeItem('overdue.date')
    localStorage.removeItem('overdue.summ')
    localStorage.removeItem('enter.shop')
    localStorage.removeItem('account.user.email')
    localStorage.removeItem('account.user.phone')
    window.location.href = '/'
}

const GetNewAccessToken = async () => {
    const requestOptions = getRequestOptionsNoAuth('POST', null, 'application/json')
    requestOptions.body = JSON.stringify({
        refresh_token: localStorage.getItem('refresh.token'),
        client_info: localStorage.getItem('client.info'),
        username: localStorage.getItem('username'),
    })
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/clients/web/refresh`, requestOptions)
    const json = await response.json()
    return json
}

export const CheckTokenLifeTime = () => {
    const tokenLifetime = 60 * 60 * 24 * 1000
    let isLogin = false

    if (localStorage.getItem('access.token.lifetime') && localStorage.getItem('access.token') && localStorage.getItem('account.user.name') && localStorage.getItem('account.entity.id') && localStorage.getItem('account.entity.name') && localStorage.getItem('enter.shop')) {
        const TOKEN_LIFETIME = parseInt(localStorage.getItem('access.token.lifetime'))
        isLogin = TOKEN_LIFETIME - parseInt(Date.now()) > 0

        if (!isLogin) {
            localStorage.setItem('history.ids', undefined)
            try {
                updateMarketingCampaignIds()
                getBanners().then(json => {
                    json.data && localStorage.setItem('banners_data', JSON.stringify(json.data))
                })
            } catch (error) {
                console.error(error)
            }
            try {
                GetNewAccessToken()
                    .then(json => {
                        console.log('GetNewAccessToken', json, typeof json)

                        if (json.access_token && json.refresh_token) {
                            localStorage.setItem('access.token', json.access_token)
                            localStorage.setItem('refresh.token', json.refresh_token)
                            localStorage.setItem('access.token.lifetime', parseInt(Date.now()) + tokenLifetime)
                            // localStorage.setItem('access.token.lifetime',parseInt(Date.now())+parseInt(json.expires_in)*1000)
                            isLogin = true
                            if (localStorage.getItem('current.url') && localStorage.getItem('current.url').length > 0) {
                                window.location.href = localStorage.getItem('current.url')
                            } else {
                                const country = localStorage.getItem('locale.full')
                                window.location.href = '/'
                                localStorage.setItem('locale.full', country)
                            }
                        } else {
                            GoToLogin()
                        }
                    }).catch((e) => {
                        console.error(e)
                        GoToLogin()
                    })
            } catch (e) {
                console.error(e)
                GoToLogin()
            }
        }
    }
}

export const ResetCart = () => {
    localStorage.removeItem('cart.uuid')
    localStorage.removeItem('cart.id')
    localStorage.removeItem('cart.id.real')
}

export const FixCompanyName = str => {
    if (companyName === 'Monolith United Kingdom') {
        return str
    } else if (str === undefined) {
        return ' '
    } else {
        let toReturn = ''
        toReturn = str.replace('Monolith United Kingdom', companyName)
        toReturn = str.replace('MONOLITH (UK) LTDUK', companyName)
        toReturn = toReturn.replace('MONOLITH (UK) LTD', companyName)
        toReturn = toReturn.replace('MONOLITH (UK)', companyName)
        toReturn = toReturn.replace('Monolith (UK) LTD', companyName)
        toReturn = toReturn.replace('Monolith (UK)', companyName)
        toReturn = toReturn.replace('Monolith UK LTD', companyName)
        toReturn = toReturn.replace('Monolith UK', companyName)
        return toReturn
    }
}

export const FetchPreferencesPost = async (lang = LangFix(localStorage.getItem('locale'))) => {
    const url = `${process.env.REACT_APP_API_DOMAIN}/api-module-preferences`
    const requestOptionsPost = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Accept-Language': lang,
            'Content-Type': 'application/json',
            'X-Tenant': xTenant,
            'X-CompanyID': xCompanyID,
            'Authorization': `Bearer ${localStorage.getItem('access.token')}`
        },
        body: JSON.stringify({
            module_id: '999',
            user_id: localStorage.getItem('user.id'),
            component_id: 'b2b',
            preferences: `{"language":"${lang}"}`
        })
    }
    const response = await fetch(url, requestOptionsPost)
    const json = await response.json()
    return json
}
export const translationsForSearchBox = () => {
    return { placeholder: t('niki.b2b.header.search_here'), submitTitle: t('niki.b2b.header.search') }
};

export const getBannerDataLogin = e => {
    const toReturn = {}
    if (e.attachments && e.attachments.data && e.attachments.data[0] && e.attachments.data[0].awsFileUrl) toReturn.image = e.attachments.data[0].awsFileUrl
    if (e.matchcode) toReturn.title = e.matchcode
    if (e.contentUrl && e.contentUrl.data && e.contentUrl.data.url) toReturn.url = e.contentUrl.data.url || ''
    if (toReturn.url) toReturn.target = toReturn.url.includes('http') ? `_blank` : `_self`
    return toReturn
}

export const returnProductIcons = (type, value, el) => {
    // niki.b2b.extrafield.wine
    // niki.b2b.extrafield.beer
    const id = Date.now()
    if (type === 'boolean') {
        if (typeof value === 'boolean' ? value : value === 'true') {
            switch (el) {
                case 4:
                    return { label: 'niki.b2b.extrafield.frozen', icon: 'Frozen', id };
                case 5:
                    return { label: 'niki.b2b.extrafield.alcohol', icon: 'Alcohol', id };
                case 9:
                    return { label: 'niki.b2b.extrafield.chilled', icon: 'Chilled', id };
                case 30:
                    return { label: 'niki.b2b.extrafield.ambient', icon: 'Ambient', id };
                case 89:
                    return { label: 'niki.b2b.extrafield.hallal', icon: 'Hallal', id };
                case 90:
                    return { label: 'niki.b2b.extrafield.kosher', icon: 'Kosher', id };
                default:
                    return ''
            }
        }
    }
    return ''
}

export const returnItemExtrafields = (items, isValue = true) => {
    if (Array.isArray(items) && items.length > 0) {
        items.forEach(el => {
            const resFilter = !!el?.id?.value ? returnProductIcons(el?.type?.value, el?.value?.value, +el?.id?.value) : returnProductIcons(el?.type, el?.value, +el?.id)
            if (!!resFilter) return resFilter
        })
    } else {
        for (let key in items) {
            const item = items[key]
            const resFilter = !!item?.id?.value ? returnProductIcons(item?.type?.value, item?.value?.value, +item?.id?.value) : returnProductIcons(item?.type, item?.value, +item?.id)
            if (!!resFilter) return resFilter
        }
    }
}

export const returnProductNameFromArray = (e, data) => {
    const selectedLanguage = LangFix(localStorage.getItem('locale'))
    let resName = ''
    data.forEach(prod => {
        if (prod.locale === selectedLanguage) {
            resName = prod.short_description ? prod.short_description : e.matchcode
        }
    })
    return resName ? resName : e.matchcode
}

export const returnStockStatusText = (qyt, UnitCode) => {
    let status = 'outofstock'
    let text = `${t('niki.b2b.outofstock')}`
    if (qyt > 20) {
        status = 'instock'
        text = `${qyt} ${UnitCode}`
    } else if (qyt > 0) {
        status = 'low'
        text = `${t('niki.b2b.low')} ${qyt}`
    }
    return ({ stockStatus: status, stockText: text })
}

export const DataPreloader = ({ qtyRows }) => {
    const arrayTemp = [...Array(parseInt(qtyRows || 10))]
    return (
        <>{arrayTemp.map((e, i) => <div key={`cartplaceholder${i}`} className="cart__content_placeholder content_placeholder"></div>)}</>
    )
}

export const getTranslatedName = (e) => {
    if (e?.itemDescriptions?.data) {
        return e.itemDescriptions.data.filter(el => el.locale === LangFix(localStorage.getItem('locale')))[0]?.name || ''
    }
}

export const getCartProducts = (e) => {
    const productName = e?.item?.data?.itemDescriptions?.data?.length > 0 ? returnProductNameFromArray(e, e.item.data.itemDescriptions.data) : e.matchcode
    const instock = e.item.data?.itemStocks?.data?.length > 0 ? e.item.data.itemStocks.data.filter(i => i.warehouse_id === localStorage.getItem('warehouse.id'))[0]?.available : 0
    // let price = e.item?.data?.itemPrices?.data[0]?.unit_price ? parseFloat(e.item.data.itemPrices.data[0].unit_price) : 0
    // let gross = e.item?.data?.itemPrices?.data[0]?.tax_included_price ? parseFloat(e.item.data.itemPrices.data[0].tax_included_price) : 0
    let priceOld = 0

    // if (e.item?.data?.itemDiscounts?.data[0]?.unit_price) {
    //     priceOld = price
    //     price = parseFloat(e.item.data.itemDiscounts.data[0].unit_price)
    //     gross = parseFloat(e.item.data.itemDiscounts.data[0].tax_included_price)
    // }

    let price = 0
    let gross = 0
    const itemPrices = e.item?.data?.itemPrices?.data || []
    if (itemPrices.length === 1) {
        price = parseFloat(itemPrices[0].unit_price)
        gross = parseFloat(itemPrices[0].tax_included_price)
    } else if (itemPrices.length > 1) {
        const itemPricesFiltered = itemPrices.filter(p => p.priceline_id === priceLineID)
        price = parseFloat(itemPricesFiltered[0]?.unit_price)
        gross = parseFloat(itemPricesFiltered[0]?.tax_included_price)
    }

    const itemDiscounts = e.item?.data?.itemDiscounts?.data || []
    if (itemDiscounts.length === 1) {
        priceOld = price
        price = parseFloat(itemDiscounts[0].unit_price)
        gross = parseFloat(itemDiscounts[0].tax_included_price)
    } else if (e.item?.data?.itemDiscounts?.data?.length > 1) {
        const itemDiscountsFiltered = itemDiscounts.filter(p => p.priceline_id === priceLineID)
        priceOld = price
        price = parseFloat(itemDiscountsFiltered[0]?.unit_price)
        gross = parseFloat(itemDiscountsFiltered[0]?.tax_included_price)
    }
    const cartData = {
        name: productName,
        id: e.id,
        itemid: e.item_id,
        family: e.item?.data?.itemFamily?.data?.description || 'zz',
        kinds_type: e.item?.data?.itemKinds?.data[0]?.real_id || 5,
        qty: e.qty,
        qtyinbox: e.qty_in_box,
        price: price,
        priceold: priceOld || price,
        gross: gross,
        productid: e.item.data.id,
        productitemid: e.item.data.item_id,
        unitcode: e.UNI,
        image: GetMainImageCart(e.item.data.attachments, e.item.data.attachment_id),
        instock: instock,
        vat: (gross - price) || 0,
        sale_unit_code: e.sale_unit_code,
        group: e.item?.data?.itemCategories?.data[0]?.name ? e.item.data.itemCategories.data[0].name : '',
        type: e.item?.data?.itemKinds?.data[0]?.name ? e.item.data.itemKinds.data[0].name : '',
        supplier: e?.item?.data?.item_type_id !== '5QrvVGorkVn14mAR' && e?.item?.data?.item_type_id !== 'rm0GNla0Q6og35Me' ? companySupplier : `${e.item?.data?.entityAccount?.data?.external_id || ''}`,
    }
    return cartData
}

export const setCartIdsToStorage = (uuid, real_id, id) => {
    localStorage.setItem('cart.uuid', uuid)
    localStorage.setItem('cart.id.real', real_id)
    localStorage.setItem('cart.id', id)
}

export const returnSortedCartData = (sortBy, cartData) => {
    switch (sortBy) {
        case 'family_asc':
            return cartData.map(p => p).sort((a, b) => a.family.localeCompare(b.family));
        case 'family_desc':
            return cartData.map(p => p).sort((a, b) => b.family.localeCompare(a.family));
        case 'type_asc':
            return cartData.map(p => p).sort((a, b) => a.kinds_type - b.kinds_type);
        case 'type_desc':
            return cartData.map(p => p).sort((a, b) => b.kinds_type - a.kinds_type);
        default:
            return []
    }
}

export const onBannerView = (banner, navigate) => {
    banner.link.includes('ids') ? navigate(`/all-products/?banner=${banner.bannerId}`) : navigate(banner.link)
    localStorage.setItem('banner.data', JSON.stringify({ productsIdArray: banner.link.slice(4).split(',') }))
}

export const checkValueOrNonInteger = (value, sale_unit_code) => {
    const regex = /^\d+(\.)?(\d{1,2})?$/
    const inputValue = sale_unit_code === 'KG' && typeof value === "string"
        ? !value
            ? ''
            : value.includes('.')
                ? value.slice(0, value.indexOf('.') + 3)
                : value
        : parseInt(value) || 0

    if (sale_unit_code === 'KG' && !regex.test(value)) {
        return ''
    } else {
        return inputValue[0] === '0' ? inputValue.slice(1) : inputValue
    }
}

export const returnProductDataForRedux = (id, name, qty, qtyinbox, unitcode, price, productid, itemid, productItemId) => {
    return {
        id: id,
        name: name,
        qty: qty,
        qtyinbox: qtyinbox,
        unitcode: unitcode,
        price: price,
        productid: productid,
        itemid: itemid,
        productitemid: productItemId,
    }
}