import { createSlice } from "@reduxjs/toolkit"

const paginationSlice = createSlice({
    name: "pagination",
    initialState: {
        count: 0,
        current_page: 0,
        next_link: '',
        per_page: 0,
        total: 0,
        total_pages: 0,
        fetching: false,
        thatsall: false,
        what_show: 'all',
        h1: 'All Products',
    },
    reducers: {
        setPagination(state, action) {
            state.count = action.payload.count
            state.current_page = action.payload.current_page
            state.next_link = action.payload.next_link
            state.per_page = action.payload.per_page
            state.total = action.payload.total
            state.total_pages = action.payload.total_pages
        },
        setFetching(state, action) {
            state.fetching = action.payload
        },
        setThatsall(state, action) {
            state.thatsall = action.payload
        },
        setWhatShow(state, action) {
            state.what_show = action.payload
        },
        setH1(state, action) {
            state.h1 = action.payload
        },
    }
})

export default paginationSlice.reducer
export const {setPagination,setFetching,setThatsall,setWhatShow,setH1} = paginationSlice.actions