import { useRef, useState, useEffect } from "react"
import { t } from "i18next"
import './../assets/css/alertpopup.css'

export const DeleteCartAlert = ({ closeAlertPopup, confirmDeletion }) => {
    const alertWrapperEl = useRef(null)

    const [isClose, setIsClose] = useState(false)
    const [isDelete, setIsDelete] = useState(false)

    useEffect(() => {
        let timer;
        
        clearTimeout(timer);
        timer = setTimeout(() => {
            alertWrapperEl.current.classList.add('active')
        }, 200); 
           
        return () => clearTimeout(timer)
    }, [])

    useEffect(() => {
        let timer;

        if (isClose && isDelete && confirmDeletion) {
            clearTimeout(timer);
            timer = setTimeout(() => confirmDeletion(), 400)
        } else if (isClose && closeAlertPopup) {
            clearTimeout(timer);
            timer = setTimeout(() => closeAlertPopup(), 400)
        }
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [isClose, isDelete])

    const onCloseAlertPopup = () => {
        setIsClose(true)
        alertWrapperEl.current.classList.remove('active')
    }
    const onConfirmDeleteion = () => {
        alertWrapperEl.current.classList.remove('active')
        setIsClose(true)
        setIsDelete(true)
    }

    return (
        <div className="shopping_carts__alert__wrapper" ref={alertWrapperEl}>
            <div className="shopping_carts__alert">
                <div className="shopping_carts__alert__text">{t('niki.b2b.shoppingcarts.confirmdeleting')}</div>
                <div className="shopping_carts__alert__buttons">
                    <button className="btn" onClick={onCloseAlertPopup}>{t('niki.b2b.shoppingcarts.no')}</button>
                    <button className="btn btn-primary" onClick={onConfirmDeleteion}>{t('niki.b2b.shoppingcarts.yes')}</button>
                </div>
            </div>
            <div className="shopping_carts__alert__overlay" onClick={onCloseAlertPopup}></div>
        </div>
    )
}