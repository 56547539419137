import React  from 'react'
import { NavLink } from 'react-router-dom'

import { ReactComponent as HomeGrey } from './../assets/svg/homegrey.svg'

export const BreadCrumbs = props => {
    return (
        <nav className="wrapper">
            <ul className="breadcrumbs">
                <li><NavLink to="/"><HomeGrey className="bradcrumbs_home" /></NavLink></li>
                {props.itemlist.map((el,i)=><li key={`breadcrumb${i}`}><NavLink to={el.url}>{el.title}</NavLink></li>)}
            </ul>
        </nav>
    )
}