export const returnGroupingSuppliers = (groupingSuppliers) => {

    const sortByExternalId = (a, b) => a.item.data.entityAccount.data.external_id - b.item.data.entityAccount.data.external_id;
    const groupedSuppliers = groupingSuppliers.sort(sortByExternalId);
    const newArr = []
    let checkArr = []
    let supplierName = groupedSuppliers[0].item.data.entityAccount.data.external_id

    groupedSuppliers.forEach((i, index) => {
        if (supplierName === i.item.data.entityAccount.data.external_id) {
            if (index+1 === groupedSuppliers.length) {
                newArr.push([...checkArr, i])
                checkArr = []
            }
            if (checkArr.length === 0) {
                checkArr.push(i)
                return
            }
            checkArr.push(i)
        } else {
            checkArr.length === 0 ? newArr.push([i]) : newArr.push(checkArr)
            if (index+1 === groupedSuppliers.length && checkArr.length === 1) {
                newArr.push([i])
            }
            checkArr = []
            supplierName = i.item.data.entityAccount.data.external_id
            checkArr.push(i)
        }
    })

    return newArr;
}

export const returnGroupingDelivery = (groupingSuppliersDelivery) => {

    const sortByExternalId = (a, b) => a.estimated_delivery - b.estimated_delivery;
    const groupedToDelivery = groupingSuppliersDelivery.sort(sortByExternalId);

    const newArr = []
    let checkArr = []
    let deliveryDate = groupedToDelivery[0].estimated_delivery

    groupedToDelivery.forEach((i, index) => {
        if (deliveryDate === i.estimated_delivery) {
            if (index+1 === groupedToDelivery.length) {
                newArr.push(checkArr)
                checkArr = []
            }
            if (checkArr.length === 0) {
                checkArr.push(i)
                return
            }
            checkArr.push(i)
        } else {
            checkArr.length === 0 ? newArr.push([i]) : newArr.push(checkArr)
            if (index+1 === groupedToDelivery.length && checkArr.length === 1) {
                newArr.push([i])
            }
            checkArr = []
            deliveryDate = i.estimated_delivery
            checkArr.push(i)
        }
    })
    return newArr;
}

// const testArr = [
//     {   qty: 25,
//         unit_price: 0.8,
//         item: {
//             data: {
//                 item_type_id: 'rm0GNla0Q6og35Me',
//                 entityAccount: {
//                     data: {
//                         external_id: "9999999",
//                         estimated_delivery: 3,
//                         id: 'qwertyuio'
//                     }
//                 },
//                 itemPrices: {
//                     data: [{ unit_price: 0.8 }]
//                 }
//             }
//         },
//         tax_included_price: 1.2
//     },
//     {   qty: 12,
//         unit_price: 2.4,
//         item: {
//             data: {
//                 item_type_id: '5QrvVGorkVn14mAR',
//                 entityAccount: {
//                     data: {
//                         external_id: "12345",
//                         estimated_delivery: 1,
//                         id: 'asdfghjkl'
//                     }
//                 },
//                 itemPrices: {
//                     data: [{ unit_price: 2.4 }]
//                 }
//             }
//         },
//         tax_included_price:2.4
//     },
//     {   qty: 8,
//         unit_price: 1.6,
//         item: {
//             data: {
//                 item_type_id: '5QrvVGorkVn14mAR',
//                 entityAccount: {
//                     data: {
//                         external_id: "222271",
//                         estimated_delivery: null,
//                         id: 'zxcvbnm12'
//                     }
//                 },
//                 itemPrices: {
//                     data: [{ unit_price: 1.6 }]
//                 }
//             }
//         },
//         tax_included_price: 2.4
//     },
//     {   qty: 15,
//         unit_price: 3.4,
//         item: {
//             data: {
//                 item_type_id: '5QrvVGorkVn14mAR',
//                 entityAccount: {
//                     data: {
//                         external_id: "9999999",
//                         estimated_delivery: 3,
//                         id: '1234567890'
//                     }
//                 },
//                 itemPrices: {
//                     data: [{ unit_price: 3.4 }]
//                 }
//             }
//         },
//         tax_included_price: 3.4
//     },
//     {   qty: 10,
//         unit_price: 2.4,
//         item: {
//             data: {
//                 item_type_id: 'rm0GNla0Q6og35Me',
//                 entityAccount: {
//                     data: {
//                         external_id: "12345",
//                         estimated_delivery: 1,
//                         id: '1qazxsw23edc'
//                     }
//                 },
//                 itemPrices: {
//                     data: [{ unit_price: 2.4 }]
//                 }
//             }
//         },
//         tax_included_price:2.4
//     },
//     {   qty: 18,
//         unit_price: 1.8,
//         item: {
//             data: {
//                 item_type_id: '5QrvVGorkVn14mAR',
//                 entityAccount: {
//                     data: {
//                         external_id: "4444444",
//                         estimated_delivery: 2,
//                         id: '4rfvbgt56yhn'
//                     }
//                 },
//                 itemPrices: {
//                     data: [{ unit_price: 1.8 }]
//                 }
//             }
//         },
//         tax_included_price:2.4 
//     }
// ]