import { createSlice } from "@reduxjs/toolkit"

const UpdateQTYs = returnsContent => {
    let qtys = {}
    returnsContent.forEach(element => qtys[element.id] = element.qtyincart)
    return qtys
}
const UpdateQTYsUUID = returnsContent => {
    let qtys = {}
    returnsContent.forEach(element => qtys[element.uuid] = element.qtyincart)
    return qtys
}
const GetReturnSelectedIDS = selectedContent => {
    let selectedContentIDs = []
    selectedContent.forEach(element => selectedContentIDs.push(element.uuid))
    return selectedContentIDs
}
const GetReturnSelectedQTYs = selectedContent => {
    let selectedContentQTYs = {}
    selectedContent.forEach(element => selectedContentQTYs[element.uuid] = element.qtyReturn)
    return selectedContentQTYs
}
const GetReturnSelectedReasons = selectedContent => {
    let selectedContentReasons = {}
    selectedContent.forEach(element => selectedContentReasons[element.uuid] = element.reason)
    return selectedContentReasons
}

const returnsSlice = createSlice({
    name: "returns",
    initialState: {
        count: 0,
        qtys: {},
        returnsContent: [],
        selectedCount: 0,
        selectedContent: [],
        selectedContentIDs: [],
        selectedContentQTYs: {},
        selectedContentReasons: {},
        uuidQTYs: {},
        reasons: [],
        // isClearReturnProducts: true,
    },
    reducers: {
        addReturnProducts(state,action) {
            state.count += 1
            state.returnsContent.push(action.payload)
            state.qtys = UpdateQTYs(state.returnsContent)
            state.uuidQTYs = UpdateQTYsUUID(state.returnsContent)
        },
        updateReturnProduct(state,action) {
            state.returnsContent.forEach(element => {
                if (element.id === action.payload.id) element.qtyincart = action.payload.qty
            })
            state.qtys = UpdateQTYs(state.returnsContent)
        },
        clearReturnProducts(state) {
            state.count = 0
            state.qtys = {}
            state.returnsContent = []
            state.selectedCount = 0
            state.selectedContent = []
            state.selectedContentIDs = []
            state.selectedContentQTYs = {}
            state.uuidQTYs = {}
            state.isClearReturnProducts = true
        },
        addReturnSelected(state,action) {
            state.selectedContent.push(action.payload)
            state.selectedCount = state.selectedContent.length
            state.selectedContentIDs = GetReturnSelectedIDS(state.selectedContent)
            state.selectedContentQTYs = GetReturnSelectedQTYs(state.selectedContent)
            state.selectedContentReasons = GetReturnSelectedReasons(state.selectedContent)
        },
        removeReturnSelected(state,action) {
            let newArray = []
            state.selectedContent.forEach(element => {
                element.uuid !== action.payload && newArray.push(element)
            })
            state.selectedContent = newArray
            state.selectedCount = state.selectedContent.length
            state.selectedContentIDs = GetReturnSelectedIDS(state.selectedContent)
            state.selectedContentQTYs = GetReturnSelectedQTYs(state.selectedContent)
            state.selectedContentReasons = GetReturnSelectedReasons(state.selectedContent)
        },
        updateReturnSelectedQTY(state,action) {
            state.selectedContent.forEach(element => {
                if (element.uuid === action.payload.uuid) element.qtyReturn = action.payload.qty
            })
            state.selectedContentIDs = GetReturnSelectedIDS(state.selectedContent)
            state.selectedContentQTYs = GetReturnSelectedQTYs(state.selectedContent)
        },
        updateReturnSelectedReason(state,action) {
            state.selectedContent.forEach(element => {
                if (element.uuid === action.payload.uuid) element.reason = action.payload.reason
            })
            state.selectedContentReasons = GetReturnSelectedReasons(state.selectedContent)
        },
        addReason(state,action) {
            state.reasons.push({id:action.payload.id,name:action.payload.name})
        },
        setIsClearReturnProducts(state,action) {
            state.isClearReturnProducts = action.payload
        },
    },
})

export default returnsSlice.reducer
export const {addReturnProducts, updateReturnProduct, clearReturnProducts, addReturnSelected, removeReturnSelected, updateReturnSelectedQTY, updateReturnSelectedReason, addReason, setIsClearReturnProducts } = returnsSlice.actions