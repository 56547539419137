// import { combineReducers, configureStore } from "redux"
import { configureStore } from "@reduxjs/toolkit"
import { combineReducers } from "@reduxjs/toolkit"
import cartSlise from "./cartSlice"
import productsSlice from "./productsSlice"
import paginationProductsSlice from "./paginationProductsSlice"
import filterSlice from "./filterSlice"
import returnsSlice from "./returnsSlice"
import allProductsSlice from './allproduct'
import checkoutSlice from "./checkoutSlice"

const rootReducer = combineReducers({
    cartContent: cartSlise,
    productsContent: productsSlice,
    paginationContent: paginationProductsSlice,
    filterContent: filterSlice,
    returnsContent: returnsSlice,
    allProductsContent: allProductsSlice,
    checkoutContent: checkoutSlice
})

export const store = configureStore({
    reducer: rootReducer,
})