import { createSlice } from "@reduxjs/toolkit"

const productsSlice = createSlice({
    name: "products",
    initialState: {
        count: 0,
        how_show: 'grid',
        productsContent: [],
        formShowOrder: '',
    },
    reducers: {
        putProducts(state,action) {
            state.count = state.count + action.payload.length
            action.payload.forEach(element => state.productsContent.push(element))
        },
        updateProduct(state,action) {
            state.productsContent.forEach(element => {
                if (element.id === action.payload.id) element.qty = action.payload.qty
            })
        },
        clearProducts(state) {
            state.count = 0
            state.productsContent = []
        },
        changeView(state,action) {
            state.how_show = action.payload
        },
        setFormShowOrder(state,action) {
            state.formShowOrder = action.payload
        }
    }
})

export default productsSlice.reducer
export const {putProducts,updateProduct,clearProducts,changeView,setFormShowOrder} = productsSlice.actions