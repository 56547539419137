import { searchClient, priceLineID, segmentationInsert, xCompanyID } from '../config.js'
import { t } from "i18next"
import { InstantSearch, Configure, Hits } from 'react-instantsearch-dom'

import { Product } from '../components/ProductCard'
import { getMeilisearchIndexName } from '../helpers/apiHelper'

import './../assets/css/staticpages.css'

const bookletFacetFilters = [
    `company_id=${xCompanyID}`,
    `itemStocks.source_warehouse_id=${localStorage.getItem('warehouse.id')}`,
    localStorage.getItem('marketingCampaignIds') ? `marketingCampaigns IN [${JSON.parse(localStorage.getItem('marketingCampaignIds'))?.booklet}]` : `is_booklet=true`,
    // `is_booklet=true`,
    `itemSegmentations IN [${segmentationInsert}]`,
]

const productHit = ({ hit }) => {
    return (
        <Product key={`product${hit.id}`} {...hit} pricelineId={priceLineID} isGrid={true}/>
    )
}

export const NoMatch = () => {
    return (
        <div className="content main_page">
            <div className="nomatch_content">
                <div className="big_404">404</div>
                <div className="oh_no">{t('niki.b2b.404.h1')}.</div>
                <div className="you_might">{t('niki.b2b.404.underh1')}.</div>
                <div className="you_can_use_search">{t('niki.b2b.404.underh12')}:</div>
                <form className="nomatch_search_form" action="/search/">
                    <input
                        type="search"
                        name="q"
                        className="form-control"
                        placeholder={t('niki.b2b.header.searchproducts')}
                        autoComplete="off"
                        required
                    />
                    <button type="submit" className="nomatch_search_form__submit"></button>
                </form>
            </div>
            <InstantSearch
                indexName={getMeilisearchIndexName("items")}
                searchClient={searchClient}
            >
                <Configure
                    facetFilters={bookletFacetFilters}
                    hitsPerPage={12}
                    attributesToHighlight={[]}
                    attributesToRetrieve={['id','ItemExtrafieldsAnswers','main_unit_code_translations','sale_unit_code_translations','company_id','entity_account_id','tax_group_id','source_id','status_id','attachment_id','source_item_id','source_family_id','source_supplier_id','source_item_type_id','source_barcode_type_id','main_unit_code','sale_unit_code','purchase_unit_code','barcode','matchcode','capacity','capacity_unit_code','width','height','length','netweight','grossweight','properties','order_apply_round','order_round_percent','order_round_unit_code','family','itemStocks',`itemPrices.${priceLineID}`,`itemDiscounts.${priceLineID}`,'is_booklet','is_new','itemUnitBarcodeByUnitCodeBox','ratings','attachment','attachments','is_discount','default_warehouse','is_in_stock','itemDescriptions','brand','country']}
                />
                <h2 className="center">{t('niki.b2b.404.booklet')}</h2>
                <div className="product_hits"><Hits hitComponent={productHit} /></div>
            </InstantSearch>
        </div>
    )
}