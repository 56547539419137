import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { deleteProductFormCart, updateProductInCartPost } from '../../helpers/apiHelper'
import { checkValueOrNonInteger } from '../../functions'
import { updateCartProduct, deleteCartProduct, changeQuickAddProductCount } from "../../reduxToolkit/cartSlice"
import '../../assets/css/shoppingcart.css'
import { ReactComponent as Trash } from '../../assets/svg/ppagetrash.svg'

export const ProductQtyChangeButtons = ({productid, qty, qtyinbox, sale_unit_code, onDelete}) => {

    const dispatch = useDispatch()

    const productQTYs = useSelector(state => state.cartContent.productQTYs)
    const cartDetailsIDs = useSelector(state => state.cartContent.cartDetailsIDs)
    const [localQty, setLocalQty] = useState(qty)
    const [isChangedInput, setChangedInput] = useState(false)
    const [isUpdatedProductQty, setIsUpdatedProductQty] = useState(false)


    useEffect(() => {
        setLocalQty(qty)
    }, [qty])


    const updateProductQty = (value) => {
        setIsUpdatedProductQty(true)
        updateProductInCartPost(cartDetailsIDs[productid], Number(value ? value : localQty))
            .then(response => {
                if (response?.data?.qty) {
                    dispatch(updateCartProduct({ productid: productid, qty: response.data.qty }))
                    dispatch(changeQuickAddProductCount(response.data.qty))
                } else {
                    setLocalQty(qty)
                }
            })
            .catch(error => console.error(error))
            .finally(() => setIsUpdatedProductQty(false))
    }

    const delayedUpdate = () => {
        if (localQty === '0' || localQty === 0) {
            deleteProduct()
        } else if (localQty) {  
            updateProductQty()         
        }
        setChangedInput(false)
    }

    useEffect(() => {
        let timer;

        if (isChangedInput) {
            clearTimeout(timer);
            if (localQty === '0' || localQty === 0) {
                dispatch(updateCartProduct({ productid: productid, qty: 0 }))
                timer = setTimeout(() => delayedUpdate(), 5000);   
            } else {
                timer = setTimeout(() => delayedUpdate(), 1800);   
            } 
        }
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [localQty]);
    
    
    const inputChangeQtyProduct = e => {
        const value = e.target.value
        if (typeof value === "string" && !value) {
            setLocalQty(0)
            setChangedInput(true)
            return
        }
       
        if (value.includes('.') && value.slice(value.indexOf('.')).length > 3) return
        
        const inputValue = checkValueOrNonInteger(value, sale_unit_code)
        if (inputValue === '') return 

        const res = sale_unit_code === 'KG' ? inputValue : parseInt(inputValue)

        if (inputValue !== localQty) {
            setChangedInput(true)
        }
        setLocalQty(res)
    }

    const plusQtyProduct = () => {
        const sum = productQTYs[productid] + parseInt(qtyinbox)
        const resultSum = typeof sum === "string" ? parseFloat(sum) : sum
        const resPlus = Number.isInteger(resultSum) ? resultSum : Number(resultSum.toFixed(2))

        setLocalQty(resPlus)
        updateProductQty(resPlus)
    }

    const minusQtyProduct = () => {
        const subtractionResult = productQTYs[productid] - parseInt(qtyinbox)
        const difference = Number.isInteger(subtractionResult) ? subtractionResult : Number(subtractionResult.toFixed(2))
       
        setLocalQty(difference)
        updateProductQty(difference)
    }

    const deleteProduct = () => {
        dispatch(deleteCartProduct(cartDetailsIDs[productid]))
        deleteProductFormCart(cartDetailsIDs[productid])
        onDelete && onDelete()
    }

    return (
        <>
            {isUpdatedProductQty
                ? <div className="wrapper_overlay_spiner"><div className="spinner_ring_red"><div></div><div></div><div></div><div></div></div></div>
                : <div className="overlay_buttons_input"> 
                    {parseInt(productQTYs[productid]) <= parseInt(qtyinbox)
                        ? <button
                            className="overlay_buttons_input__button"
                            onClick={deleteProduct}
                        ><Trash /></button>
                        : <button
                            className="overlay_buttons_input__button minus"
                            onClick={minusQtyProduct}
                        >-</button>
                    }
                    <input
                        className="overlay_buttons_input__input"
                        value={localQty}
                        onChange={inputChangeQtyProduct}
                    />
                    <button
                        className="overlay_buttons_input__button plus"
                        onClick={plusQtyProduct}
                    >+</button>
                </div>
            } 
        </>
    )
}