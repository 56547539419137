import { createSlice } from "@reduxjs/toolkit"

const checkoutSlice = createSlice({
    name: "checkout",
    initialState: {
        deliveryMethod: 'standarddelivery',
        paymentMethod: '',
        additionalNotes: '',
        warehousePickup: '',
        startDate: {},
        startDateWarehouse: {},
        // deliveryDates: [],
        dataCartDocumentRequest: [],
    },
    reducers: {
        changeDeliveryMethod(state,action) {
            state.deliveryMethod = action.payload
        },
        changePaymentMethod(state,action) {
            state.paymentMethod = action.payload
        },
        changeAdditionalNotes(state,action) {
            state.additionalNotes = action.payload
        },
        changeWarehousePickup(state,action) {
            state.warehousePickup = action.payload
        },
        changeStartDate(state,action) {
            state.startDate = action.payload
        },
        changeStartDateWareHouse(state,action) {
            state.startDateWarehouse = action.payload
        },
        // setDeliveryDates(state, action) {
        //     state.deliveryDates.push(action.payload)
        // },
        setDataCartDocumentRequest(state, action) {
            state.dataCartDocumentRequest = action.payload
        },
        // updateDeliveryDates(state,action) {
        //     state.deliveryDates.forEach(element => {
        //         if (element.company_name === action.payload.company_name) element.date = action.payload.date
        //     })
        // },
    }
})


export default checkoutSlice.reducer
export const {changeAdditionalNotes,changeDeliveryMethod,changePaymentMethod,changeWarehousePickup,changeStartDate,changeStartDateWareHouse,setDataCartDocumentRequest} = checkoutSlice.actions