import { useEffect, useRef } from "react"
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { t } from 'i18next'

import { setIsOpenNoCartAlert, setErrorMessage, setIsOpenModalCreateNewCart  } from '../reduxToolkit/allproduct' 

import { AlertPopup } from './AlertPopup'
import { RenameCart } from './MyShoppingCart/RenameCart'

export const WraperForModals = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const cartRenameOrCreateNewRef = useRef(null)
 
    const errorMessage = useSelector(state => state.allProductsContent.errorMessage)
    const isOpenNoCartAlert = useSelector(state => state.allProductsContent.isOpenNoCartAlert)
    const isOpenModalCreateNewCart = useSelector(state => state.allProductsContent.isOpenModalCreateNewCart)

    useEffect(() => {
        isOpenModalCreateNewCart && cartRenameOrCreateNewRef.current.classList.add('active')
    }, [isOpenModalCreateNewCart])

    const onSelectCart = () => {
        dispatch(setIsOpenNoCartAlert(false))
        navigate('/shoppingcarts/')
    }

    const onCreateNewCart = () => {
        dispatch(setIsOpenNoCartAlert(false))
        dispatch(setIsOpenModalCreateNewCart(true))
    }

    const onCloseRenamePopup = () => {
        dispatch(setIsOpenModalCreateNewCart(false))
        cartRenameOrCreateNewRef.current.classList.remove('active')
    }
    
    return (
        <>
            {errorMessage && <AlertPopup text={errorMessage} closeAlert={() => dispatch(setErrorMessage(''))} />}
            {isOpenNoCartAlert &&
                <AlertPopup text={t('niki.b2b.cart_inactive')} titleFirstButton={'niki.b2b.select_cart_from_exist'} titleSecondButton={'niki.b2b.cartflow.createnewcart'}
                    closeAlert={() => dispatch(setIsOpenNoCartAlert(false))} onFirstButtonClick={onSelectCart} onSecondButtonClick={onCreateNewCart} towButtons={true}
                />
            }
            {isOpenModalCreateNewCart &&
                <div className="rename_cart__wrapper" ref={cartRenameOrCreateNewRef}>
                    <RenameCart orderID={''} orderIDReal={''} closeRenamePopup={onCloseRenamePopup} isCreateCartLocal={true} />
                    <div className="rename_cart__overlay" onClick={onCloseRenamePopup}></div>
                </div>
            }
        </>
    )
}