import { useDispatch } from 'react-redux'
import { changeAdditionalNotes, changePaymentMethod } from '../../reduxToolkit/checkoutSlice.js'
import { t } from "i18next"
import { LangFix } from '../../functions'

import '../../assets/css/checkout.css'

export const PaymentMethod = () => {
    const dispatch = useDispatch()
    const PaymentMethodChange = e => {
        dispatch(changePaymentMethod(e.target.value))
    }
    const getPaymentTranslations = (paymentObject) => {
        return JSON.parse(paymentObject)[LangFix(localStorage.getItem('locale'))] ? JSON.parse(paymentObject)[LangFix(localStorage.getItem('locale'))] : ''
    }

    return (
        <div className="delivery_grey">
            <div className="marginbottom20">{t('niki.b2b.cartflow.yourpaymentcontract')}</div>
            {/* <label className="delivery_grey__radiobutton__simple"><input type="radio" name="payment" value="1" defaultChecked="true" onClick={PaymentMethodChange} />{t('niki.b2b.cartflow.cashondelivery')}</label> */}
            <label className="delivery_grey__radiobutton__simple">
                <input type="radio" name="payment" value={localStorage.getItem('payment.id')} defaultChecked="true" onClick={PaymentMethodChange} />
                {
                    `${getPaymentTranslations(localStorage.getItem('payment_type_name_translations')) || localStorage.getItem('payment.name')} — 
                    ${getPaymentTranslations(localStorage.getItem('payment_condition_matchcode_translations')) || localStorage.getItem('payment.condition')}`
                }
            </label>
        </div>
    )
}

export const AdditionalNotesContent = () => {
    const dispatch = useDispatch()
    const AdditionalNotesChange = e => {
        dispatch(changeAdditionalNotes(e.target.value))
    }
    return (
        <div className="delivery_grey">
            <div className="marginbottom20">{t('niki.b2b.cartflow.ifyouhaveany')}</div>
            <textarea className="form-control" placeholder={t('niki.b2b.productpage.additionalinfomation')} onChange={AdditionalNotesChange}></textarea>
        </div>
    )
}