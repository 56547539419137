import { xCompanyID, xTenant } from '../config.js'
import PWAPrompt from "react-ios-pwa-prompt"
import "../assets/css/login.css"

export const LoginHeader = () => {
    return (
        <>
            <a href="/" className="login__header"><img src={`/logo/${xTenant}_${xCompanyID}.svg`} alt="Logo" /></a>
            <PWAPrompt />
        </>
    )
}