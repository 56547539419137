import { useState, useEffect } from "react";

export const OTPRead = () => {
    const [otp,setOtp] = useState('')

    useEffect(()=>{
        if ("OTPCredential" in window) {
        const ac = new AbortController();

        navigator.credentials
          .get({
            otp: { transport: ["sms"] },
            signal: ac.signal
          })
          .then((otp) => {
            setOtp(otp.code)
            ac.abort();
          })
          .catch((err) => {
            ac.abort();
            console.log(err);
          });
      }
    },[])

    return (
      <div className="App">
        <h1>Hello CodeSandbox</h1>
        <h2>Your OTP is: {otp}</h2>
      </div>
    )
}