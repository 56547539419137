import { useRef, useState, useEffect } from "react"
import { t } from "i18next"
import './../assets/css/alertpopup.css'

export const AlertPopup = ({ text, addclass, closeAlert, towButtons, titleFirstButton, onFirstButtonClick, titleSecondButton, onSecondButtonClick, isPadding = false }) => {
    const alertWrapperEl = useRef(null)
    const [isClose, setIsClose] = useState(false)
    const [isCloseAlertFirstButton, setIsCloseAlertFirstButton] = useState(false)
    const [isCloseAlertSecondButton, setIsCloseAlertSecondButton] = useState(false)
    
    useEffect(() => {
        let timer;

        clearTimeout(timer);
        timer = setTimeout(() => {
            alertWrapperEl.current.classList.add('active')
        }, 200); 
           
        return () => clearTimeout(timer)
    }, [])

    useEffect(() => {
        let timer;

        if (isClose && closeAlert) {
            clearTimeout(timer);
            timer = setTimeout(() => closeAlert(), 400)
        } else if (isCloseAlertFirstButton && onFirstButtonClick) {
            clearTimeout(timer);
            timer = setTimeout(() => onFirstButtonClick(), 400)
        } else if (isCloseAlertSecondButton && onSecondButtonClick) {
            clearTimeout(timer);
            timer = setTimeout(() => onSecondButtonClick(), 400)
        }
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [isClose, isCloseAlertFirstButton, isCloseAlertSecondButton])

    const CloseAlertPopup = () => {
        setIsClose(true)
        alertWrapperEl.current.classList.remove('active')
    }

    const firstButtonClick = () => {
        setIsCloseAlertFirstButton(true)
        alertWrapperEl.current.classList.remove('active')
    }

    const secondButtonClick = () => {
        setIsCloseAlertSecondButton(true)
        alertWrapperEl.current.classList.remove('active')
    }

    return (
        <div className={[`alert_popup__wrapper`, addclass].join(' ')} ref={alertWrapperEl}>
            <div className={["alert_popup__content", isPadding ? "alert_popup__content_padding_50_10" : ""].join(' ')}>
                <div className={["alert_popup__content__text", isPadding ? "alert_popup__text_align_left" : "", "dangerouslyInnerHTM__container_text"].join(' ')} dangerouslySetInnerHTML={{ __html: text }} />
                {towButtons && <button className="btn btn-primary" onClick={firstButtonClick}>{t(titleFirstButton)}</button>}
                {titleSecondButton
                    ? <button className="btn btn-primary" onClick={secondButtonClick}>{t(titleSecondButton)}</button>
                    : <button className="btn btn-primary" onClick={CloseAlertPopup}>{t('niki.b2b.returnproducts.close')}</button>
                }
                <button className="alert_popup__content__close" onClick={CloseAlertPopup}></button>
            </div>
            <div className="alert_popup__overlay" onClick={CloseAlertPopup}></div>
        </div>
    )
}