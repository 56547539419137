import React, { useEffect, useState }  from 'react'
import { useDispatch } from 'react-redux'
import { changeDeliveryMethod, changeWarehousePickup, changeStartDate, changeStartDateWareHouse, setDataCartDocumentRequest } from '../../reduxToolkit/checkoutSlice.js'
import { t } from "i18next"

import CustomDatePicker from "../DatePicker"

import { ReactComponent as TruckSmall } from '../../assets/svg/checkoutTruckSmall.svg'
import { ReactComponent as MonolitSmall } from '../../assets/svg/monolithIconSmall.svg'

import '../../assets/css/checkout.css'

const today = new Date();
const milliseconds = today.getTime();
const formatDate = (date) => new Date(date.split('/').reverse().join('/'));

const DeliveryMethod = ({suppliersData, deliveriesData, step}) => {

    const dispatch = useDispatch()
    const [deliveryMethod,setDeliveryMethod] = useState('1')

    function getNextDay() {
        const nextDate = new Date();
        nextDate.setDate(nextDate.getDate() + 1);
        const month = `${nextDate.getMonth() + 1}`
        return `${nextDate.getDate()}/${month.length < 2 ? '0'+month : month}/${nextDate.getFullYear()}`
    }

    const warehouseAddress = localStorage.getItem('warehouse.address') ? localStorage.getItem('warehouse.address') : ''
    // eslint-disable-next-line
    const [estimatedDelivery, setEstimatedDelivery] = useState(getNextDay());
    const [startDate, setStartDate] = useState(warehouseAddress ? formatDate(estimatedDelivery) : new Date())
    const [startDateWarehouse, setStartDateWarehouse] = useState(warehouseAddress ? formatDate(estimatedDelivery) : new Date())
    const warehouseAddressID = localStorage.getItem('warehouse.id')
    const warehouseAddressShort = warehouseAddress?.slice(0, warehouseAddress.lastIndexOf(','))

    useEffect(()=>{
        localStorage.setItem('start.date',warehouseAddress ? formatDate(estimatedDelivery) : new Date())
        localStorage.setItem('start.date.warehouse',warehouseAddress ? formatDate(estimatedDelivery) : new Date())
        dispatch(changeStartDate(warehouseAddress ? formatDate(estimatedDelivery).toISOString() : new Date().toISOString()))
        dispatch(changeStartDateWareHouse(warehouseAddress ? formatDate(estimatedDelivery).toISOString() : new Date().toISOString()))
        // eslint-disable-next-line
    },[])
    const setStartDateLS = date => {
        localStorage.setItem('start.date',date)
        dispatch(changeStartDate(date.toISOString()))
        setStartDate(date)
    }
    const setStartDateWarehouseLS = date => {
        localStorage.setItem('start.date.warehouse',date)
        dispatch(changeStartDateWareHouse(date.toISOString()))
        setStartDateWarehouse(date)
    }
    const DeliveryMethodChange = e => {
        setDeliveryMethod(e.target.value === 'standarddelivery' ? '1' : '2')
        dispatch(changeDeliveryMethod(e.target.value))
    }
    const WarehousePickupChange = e => {
        dispatch(changeWarehousePickup(e.target.value))
    }

    const returnRowSuplier = (supplier, i) => {
        return (
            <div className="checkout_accorderon__supplier_info" key={i}>
                <div>
                    {supplier?.supplierName?.includes('Monolit') ? <MonolitSmall /> : <TruckSmall />}
                    {!supplier?.supplierName?.includes('Monolit') && t('niki.b2b.cart.partner')} {supplier?.supplierName}
                </div>
                <div>{t('niki.b2b.cartflow.totalskus')}: <div>{supplier?.qty}</div></div>
            </div>
        ) 
    }

    const [startDateMulti, setStartDateMulti] = useState([])

    const handleStartDateMulti = (id, value) => {
        const newStates = [...startDateMulti]
        newStates[id] = value
        setStartDateMulti(newStates)
    }

    useEffect(() => {
        if (step === 'payment') {
            const arr = []
            const getDataCartDocument = (item,day) => {
                const date = new Date(day);
                const month = `${date.getMonth() + 1}`
                arr.push({ entity_account_id: item.entity_account_id, delivery_at: `${date.getFullYear()}-${month.length < 2 ? '0' + month : month}-${date.getDate()}` })
            }

            if (deliveriesData.length > 0) {
                deliveriesData.forEach((i, index) => {
                    if (i.length > 0) {
                        i.forEach(s => getDataCartDocument(s,startDateMulti[index]))
                    } else {
                        getDataCartDocument(i,startDateMulti[index])
                    }
                }) 
            } else {
                suppliersData.forEach(i => getDataCartDocument(i,startDate))
            }
            dispatch(setDataCartDocumentRequest(arr))
        }  
        // eslint-disable-next-line
    }, [step])

    const getDateSupplier = (supplierName, howDate) => {
        howDate === null
            ? handleStartDateMulti(supplierName, today.setTime(milliseconds + 86400000))
            : handleStartDateMulti(supplierName, today.setTime(milliseconds + 86400000 * howDate))
    }

    return (
        <>
            <div className="delivery_grey">
                <label className="delivery_grey__radiobutton">
                    <span><input type="radio" value="standarddelivery" name="delivery" defaultChecked={true} onClick={DeliveryMethodChange} /></span>
                    <span>{t('niki.b2b.cartflow.standarddelivery')}</span>
                    <span>{t('niki.b2b.cartflow.free')}</span>
                </label>
                <div className="marginbottom20">{t('niki.b2b.cartflow.expecteddeliverylong')}</div>
                {suppliersData.length > 0 
                    ? deliveriesData.length > 0
                        ? deliveriesData.map((dellivery, i) => {
                            return <div key={i}>
                                <div className="delivery__count_name">{t('niki.b2b.cartflow.delivery')} {i + 1}</div>
                                <div className="delivery_grey__white">
                                    <strong>{t('niki.b2b.cartflow.expecteddelivery')}:</strong>
                                    <CustomDatePicker
                                        selected={startDateMulti[i] || getDateSupplier(i, dellivery[0].estimated_delivery ? dellivery[0].estimated_delivery+1 : null)}
                                        setStartDate={(e) => handleStartDateMulti(i, e)}
                                        calendarStartDay={dellivery[0].estimated_delivery ? dellivery[0].estimated_delivery+1 : null}
                                        multipleId={i}
                                        tomorow={true}
                                        disabled={deliveryMethod !== '1'}
                                        dayWeek={new Date(startDate).getDay()}
                                    />
                                </div>
                                {dellivery.map((supplier, i) => returnRowSuplier(supplier, i))}
                            </div>
                        })
                        : <>
                            <div className="delivery_grey__white">
                                <strong>{t('niki.b2b.cartflow.expecteddelivery')}:</strong>
                                <CustomDatePicker selected={startDate} setStartDate={setStartDateLS} tomorow={true} disabled={deliveryMethod !== '1'} dayWeek={new Date(startDate).getDay()} />
                            </div>
                            {suppliersData.map((supplier, i) => returnRowSuplier(supplier, i))}
                        </>
                    : <div className="delivery_grey__white">
                        <strong>{t('niki.b2b.cartflow.expecteddelivery')}:</strong>
                        <CustomDatePicker selected={startDate} setStartDate={setStartDateLS} tomorow={true} disabled={deliveryMethod !== '1'} dayWeek={new Date(startDate).getDay()} />
                    </div>
                }
            </div>
            <div className="delivery_grey">
                <label className="delivery_grey__radiobutton">
                    <span><input type="radio" value="warehousepickup" name="delivery" defaultChecked={false} onClick={DeliveryMethodChange} /></span>
                    <span>{t('niki.b2b.cartflow.warehousepickup')}</span>
                    <span>{t('niki.b2b.cartflow.free')}</span>
                </label>
                <div className="warehouseinfo">
                    <strong>{t('niki.b2b.cartflow.londonwarehouse')}</strong>
                    <br />
                    {warehouseAddress}
                </div>
                {suppliersData.length > 0 
                    ? <>
                        <div className="delivery_grey__white marginbottom20">
                            <strong>{t('niki.b2b.cartflow.expecteddelivery')}:</strong>
                            <CustomDatePicker filterDate={true} selected={startDateWarehouse} setStartDate={setStartDateWarehouseLS} tomorow={true} disabled={deliveryMethod !== '2'} dayWeek={new Date(startDateWarehouse).getDay()} />
                        </div>
                        {suppliersData.map((supplier, i) => returnRowSuplier(supplier, i))}
                    </>
                    : <div className="delivery_grey__white marginbottom20">
                        <strong>{t('niki.b2b.cartflow.expecteddelivery')}:</strong>
                        <CustomDatePicker filterDate={true} selected={startDateWarehouse} setStartDate={setStartDateWarehouseLS} tomorow={true} disabled={deliveryMethod !== '2'} dayWeek={new Date(startDateWarehouse).getDay()} />
                    </div>
                }
                <div className="marginbottom20 strong">{t('niki.b2b.cartflow.changepickuplocation')}</div>
                <select className="form-control marginbottom20 warehouseselect" onChange={WarehousePickupChange}>
                    <option value={warehouseAddressID}>{warehouseAddressShort}</option>
                </select>
            </div>
        </>
    )
}

export default DeliveryMethod;