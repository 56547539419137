import { useState, useEffect } from "react"
import { t } from 'i18next'

import './../assets/css/allproducts.css'

import { ReactComponent as TruckSmall } from './../assets/svg/checkoutTruckSmall.svg'
  
export const IconSuplierDateDelivery = ({viewGrid, prodPage}) => {
    
    const [openPopupSuplier, setOpenPopupSuplier] = useState(false)
    
    useEffect(() => {
        let timer;

        if (openPopupSuplier) {
            clearTimeout(timer);
            timer =  setTimeout(()=> setOpenPopupSuplier(false), 3000)
        }
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [openPopupSuplier])

    return (
        <div className={['item_id_area_truc', viewGrid && 'item_id_area_truc_view_list', prodPage && 'item_truc__product_page' ].join(' ')} onClick={() => { setOpenPopupSuplier(true)}}>
            {openPopupSuplier && <div>
                <div><strong>{t('niki.b2b.cartflow.estimateddelivery')}</strong></div>
                {t('niki.b2b.product.could_different')}
            </div>}
            <TruckSmall/>
        </div>   
    ) 

}