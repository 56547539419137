
import { useEffect, useState, useRef } from 'react'

import classes from './LoadableImage.module.css'
import cn from 'classnames'


const LoadableImage = ({ src, alt, styleContainer, styleImg, height }) => {
    const imageRef = useRef(null)    
    
    const [isLoaded, setIsLoaded] = useState(false)
    const [isShowImg, setIsShowImg] = useState(false)

    useEffect(() => {
        if (imageRef.current) {
            imageRef.current.onload = () => {setIsLoaded(true)}
       }
    }, [])

    useEffect(() => {
        if (!isLoaded) {
            setIsShowImg(true)
        }
    }, [isLoaded])
   
    return (
        <div className={cn(classes.container, { [classes.containerLoaded]: isLoaded })}
            style={{ height: !isLoaded ? height : 'auto', ...styleContainer }}
        >
            <img ref={imageRef} src={src} alt={alt} className={cn(classes.image, {
                [classes.imageLoaded]: isShowImg
            })} style={{...styleImg}} />
        </div>
    )
}

export default LoadableImage;