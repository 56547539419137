import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"
import { t } from "i18next"

import { xCompanyID, xTenant } from './../config.js'
import { getRequestOptions } from '../helpers/apiHelper'
import { ShowLoader, HideLoader } from '../functions'

import { BreadCrumbs } from "./../components/BreadCrumbs"
import { TwoContacts } from "../components/TwoContacts"

import { ReactComponent as UploadImages } from './../assets/svg/feedbackuploadimages.svg'

const FormatDate = inputData => {
    const fullDate = new Date(inputData)
    return `${String(fullDate.getDate()).padStart(2,'0')}.${String(fullDate.getMonth() + 1).padStart(2, '0')}.${fullDate.getFullYear()}`
}
const FormatDateTime = inputData => {
    const fullDate = new Date(inputData)
    return `${String(fullDate.getDate()).padStart(2,'0')}.${String(fullDate.getMonth() + 1).padStart(2, '0')}.${fullDate.getFullYear()} at ${fullDate.getHours()}:${String(fullDate.getMinutes()).padStart(2,'0')}`
}

const GetFeedbackData = async feedbackID => {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/feedbacks/${feedbackID}?realKey=true&include=feedbackType,status`,getRequestOptions())
    const json = await response.json()
    return json
}
const GetMessageData = async messageID => {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/message/${messageID}?include=comments,comments.attachments`,getRequestOptions())
    const json = await response.json()
    return json
}
const GetCommentData = async (messageID) => {

    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/comments?realKey=1&message_id=${messageID}&files=true&include=attachments&layout=collection`,getRequestOptions())
    const json = await response.json()
    return json
}
// const GetImageByURL = async url => {
//     const config = {
//         headers: {
//             'content-type': 'multipart/form-data',
//             'X-Tenant': process.env.REACT_APP_XTENANT,
//             'X-CompanyID': xCompanyID,
//             'Authorization': `Bearer ${localStorage.getItem('access.token')}`,
//         },
//         responseType: 'blob',
//     }
//     const res = await axios.get(url,config)
//     return res
// }

const defaultFeedbackData = {id: '', processID: '', replyEmail: '', date: '', name: ''}

const FeedbackDetailsContent = props => {
    const feedbackID = props.feedbackID
    
    const [userMessage,setUserMessage] = useState('')
    const [messageIDGlobal,setMessageIDGlobal] = useState('')
    const [commentIDFirst,setCommentIDFirst] = useState('')
    const [attachments,setAttachments] = useState({})
    const [creatorName,setCreatorName] = useState('')
    const [uploadedImages,setUploadedImages] = useState([])
    const [messageText,setMessageText] = useState([])
    const [sendCopyEmail,setSendCopyEmail] = useState('false')
    const [comments,setComments] = useState([])
    const [feedbackData,setFeedbackData] = useState(defaultFeedbackData)

    useEffect(()=>{
        ShowLoader()
        try {
            GetFeedbackData(feedbackID)
            .then(json => {
            json.data && setFeedbackData({
                id: json.data.id_real,
                processID: json.data.process_id,
                processIDReal: json.data.process_id_real,
                replyEmail: json.data.reply_email,
                date: json.data.created_at,
                subject: json.data.matchcode,
                name: json.data.reply_name,
                orderID: json.data.document_id_real,
                messageID: json.data.message_id,
                status: json.data.status && json.data.status.data && json.data.status.data.matchcode.toLowerCase(),
            })
            json.data.message_id && setMessageIDGlobal(json.data.message_id)
            json.data.message_id && GetMessageData(json.data.message_id).then(json => {
                if (json?.data?.comments?.data[0] && json.data.comments.data[0].value && json.data.comments.data[0].value.message) {
                    setCommentIDFirst(json.data.comments.data[0].id)
                    setUserMessage(json.data.comments.data[0].value.message)
                }
                setComments([])
                if (json?.data?.comments?.data && json.data.comments.data.length > 0) {
                    json.data.comments.data.forEach(e => {
                        let imgArr = []
                        if (e.attachments && e.attachments.data && e.attachments.data.length > 0) {
                            e.attachments.data.forEach(attachment => {
                                const url = attachment.awsFileUrl || attachment.url
                                url && imgArr.push(url)
                            })
                        }
                        const tempObj = {
                            id: e.id,
                            name: e.creator.data.Name,
                            date: e.created_at,
                            message: e.value.message,
                            attachments: imgArr,
                        }
                        setCreatorName(e.creator.data.Name)
                        setComments(prevState => ([...prevState, tempObj]))
                    })
                }
            })
            json.data.message_id && GetCommentData(json.data.message_id).then(json => {
                setAttachments({})
                if (json.data && json.data.length > 0) {
                    json.data.forEach(e => {
                        const commentIDL = e.id
                        let imgArr = []
                        if (e.attachments && e.attachments.data && e.attachments.data.length > 0) {
                            e.attachments.data.forEach(attachment => {
                                const url = attachment.awsFileUrl || attachment.url
                                url && imgArr.push(url)
                            })
                        }
                        const newObj = attachments
                        newObj[commentIDL] = imgArr
                        setAttachments(newObj)
                    })
                }
            })
        })
        } catch(error) {
            console.error(error)
        } finally {
            HideLoader()
        }
        // eslint-disable-next-line
    },[])

    const AddReplyForm = e => {
        e.preventDefault()
        const requestOptionsComment = getRequestOptions('POST', null, 'application/json')
        requestOptionsComment.body = JSON.stringify({
            text: messageText,
            send_copy_to_email: sendCopyEmail
        })
        
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/messages/${messageIDGlobal}/comments?include=comments,comments.attachments`,requestOptionsComment)
        .then(response => response.json())
        .then(json => {
            if (json.data) {
                const localCommentID = json.data.id
                const tempObj = {
                    id: localCommentID,
                    name: creatorName,
                    date: json.data.created_at,
                    message: json.data.value.message,
                    attachments: uploadedImages
                }
                setComments(prevState => ([...prevState, tempObj]))
                setUploadedImages([])
                setMessageText('')
            }
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
    }

    const SelectFile = e => {
        let data = new FormData()

        const files = Array.from(e.target.files)
        files.forEach(file => data.append('attachments[]',file))

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'X-Tenant': xTenant,
                'X-CompanyID': xCompanyID,
                'Authorization': `Bearer ${localStorage.getItem('access.token')}`,
            },
        }
        ShowLoader()
        axios.post(`${process.env.REACT_APP_API_DOMAIN}/messages/${messageIDGlobal}/comment-upload`,data,config)
        .then(res => {
            if (res.data && res.data.length > 0) {
                res.data.forEach(e => {
                    const url = e.awsFileUrl || e.url
                    setUploadedImages(prevState => ([...prevState, url]))
                    // GetImageByURL(url).then(res => {
                    //     const image = URL.createObjectURL(res.data)
                    //     setUploadedImages(prevState => ([...prevState, image]))
                    // })
                })
            }
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
    }
    
    return (
        <>
            <BreadCrumbs itemlist={[
                {'url':'/account/','title':t('niki.b2b.menu.myaccount')},
                {'url':'/feedback/','title':t('niki.b2b.menu.feedback')},
                {'url':`/feedback-status/${feedbackID}/`,'title':`${t('niki.b2b.menu.feedback')} ${feedbackData.id}`},
            ]} />
            <div className="feedback_content">
                <h1>{t('niki.b2b.menu.feedback')} {feedbackData.id}</h1>
                {feedbackData.status && <div className={[`alert`,feedbackData.status].join(' ')}>{t('niki.b2b.feedback.status')} {feedbackData.status}</div>}  
                <div className="feedback_details">
                    <h2>{t('niki.b2b.feedback.details')}</h2>
                    <div className="flex-spacebeetwen">
                        <strong>{t('niki.b2b.feedback.id')}</strong>
                        <span>{feedbackData.id}</span>
                    </div>
                    <div className="flex-spacebeetwen">
                        <strong>{t('niki.b2b.feedback.orderid')}</strong>
                        <span>{feedbackData.orderID}</span>
                    </div>
                    <div className="flex-spacebeetwen">
                        <strong>{t('niki.b2b.debts.processid')}</strong>
                        <span>{feedbackData.processIDReal}</span>
                    </div>
                    <div className="flex-spacebeetwen">
                        <strong>{t('niki.b2b.product.date')}</strong>
                        <span>{feedbackData.date && FormatDate(feedbackData.date)}</span>
                    </div>
                </div>

                <div className="feedback_from_user">
                    <h2>{feedbackData.subject}</h2>
                    <div className="feedback_from_user__sent_by">{t('niki.b2b.feedback.sentby')}: {(feedbackData.date || feedbackData.name) && `${feedbackData.name}, ${t('niki.b2b.feedback.on')} ${FormatDateTime(feedbackData.date)}`}</div>
                    <div className="feedback_from_user__text">{userMessage}</div>
                    {(attachments[commentIDFirst] && attachments[commentIDFirst].length > 0) &&
                        <>
                            <div className="feedback_from_user__attachments">{t('niki.b2b.feedback.attachments')}</div>
                            <div className="feedback_from_user__attachments_images">
                                {attachments[commentIDFirst].map((e,i) =>
                                    <a href={e} target="_blank" rel="noopener noreferrer nofollow" key={`mainfeedbackattachmentone${i}`}><img src={e} alt="Feedback Attachment" /></a>
                                )}
                            </div>
                        </>
                    }
                </div>
                
                {comments.length > 0 && comments.map((e,i) =>
                    <div className="feedback__reply_from" key={`reply${i}`} id={e.id}>
                        <h2>{t('niki.b2b.feedback.replyfrom')} {e.name}</h2>
                        <div className="feedback_from_user__sent_by">{t('niki.b2b.feedback.sentby')}: {(e.name || e.date ) && `${e.name}, ${t('niki.b2b.feedback.on')} ${FormatDateTime(e.date)}`}</div>
                        <div>{e.message}</div>
                        {(e.attachments && e.attachments.length > 0) &&
                            <div className="feedback_from_user__attachments_images">
                                {e.attachments.map((e,i) =>
                                    <a href={e} target="_blank" rel="noopener noreferrer nofollow" key={`feedbackattachmenttwo${i}`}><img src={e} alt="Feedback Attachment" /></a>
                                )}
                            </div>
                        }
                    </div>
                )}

                <form className="feedback__add_reply" onSubmit={AddReplyForm}>
                    <h2 className="marginbottom15">{t('niki.b2b.feedback.addreply')}</h2>
                    <div className="marginbottom10">
                        <textarea
                            className="form-control"
                            placeholder="Message"
                            value={messageText}
                            onChange={e => setMessageText(e.target.value)}
                            required
                        ></textarea>
                    </div>
                    <div className="marginbottom15">
                        <label className="btn">
                            <input type="file" className="hidden" name="file" onChange={SelectFile} multiple accept="image/*" />
                            {t('niki.b2b.feedback.uploadimages')}
                            <UploadImages />
                        </label>
                    </div>
                    {uploadedImages.length > 0 &&
                        <div className="feedback_from_user__attachments_images_upload">
                            {uploadedImages.map((e, i) => <img src={e} alt="Uploaded" key={`uploadedimage${i}`} />)}
                        </div>
                    }
                    <label className="feedback__checkboxes">
                        <input type="checkbox"
                            onChange={e => setSendCopyEmail(e.target.checked ? 'true' : 'false')}
                        /> {t('niki.b2b.feedback.copytoemail')}
                    </label>
                    <div><button className="btn btn-info">{t('niki.b2b.feedback.submit')}</button></div>
                </form>

                <hr />

                <div className="your_designated_support_team">{t('niki.b2b.account.supportteam')}</div>
                <TwoContacts />
            </div>
        </>
    )
}

export const FeedbackDetails = () => {
    const { id } = useParams()
    return (
        <div className="content">
            <FeedbackDetailsContent feedbackID={id} />
        </div>
    )
}