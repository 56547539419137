import { useRef } from "react"
import { t } from "i18next"
import './../assets/css/staticpages.css'

export const ModalShowRows = props => {
        const { title1, title, ClosePopup, content, buttonOne, buttonTwo, onClickOneButton, addclass } = props

    const alertWrapperEl = useRef(null)
    return (
        <div className={[`modal_popup__wrapper`,addclass].join(' ')} ref={alertWrapperEl}>
            <div className="modal_popup__content">
                <div className="modal_popup__header">{t(title)}</div>
                <div className="modal_popup__r_u_sure">{t(title1)}</div>
                <div className="wrapper_content_show_rows">
                    {content.map(email => <div key={email.email}>{email.email}</div>)}
                </div>
                    {buttonTwo
                        ? <div className="rename_cart__popup__buttons_local">
                            <button className="btn btn-primary">{t("niki.b2b.shoppingcart.deletecart")}</button>
                            <button className="btn" type="button" onClick={ClosePopup}>{t('niki.b2b.cartflow.cancel')}</button>
                        </div>
                        : <div className="wrapper_bottom_button">
                            <button className="btn btn-primary" onClick={onClickOneButton}>
                                {t(buttonOne ? buttonOne : 'niki.b2b.cartflow.cancel')}
                            </button>
                        </div>
                    }
                <button className="modal_popup__content__close" onClick={ClosePopup}></button>
                </div>
            <div className="modal_popup__overlay" onClick={ClosePopup}></div>
        </div>
    )
}
