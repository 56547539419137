import { useState } from "react"
import { t } from 'i18next'

import './../assets/css/allproducts.css'

import { ReactComponent as Alcohol } from './../assets/svg/producticons/alcohol.svg'
import { ReactComponent as Chilled } from './../assets/svg/producticons/chilled.svg'
import { ReactComponent as Fish } from './../assets/svg/producticons/fish.svg'
import { ReactComponent as Frozen } from './../assets/svg/producticons/frozen.svg'
import { ReactComponent as Ambient } from './../assets/svg/producticons/ambient.svg'
import { ReactComponent as Hallal } from './../assets/svg/producticons/hallal.svg'
import { ReactComponent as Kosher } from './../assets/svg/producticons/kosher.svg'
  
export const IconProduct = ({ icon, index }) => {
    const [isLabelVisible, setIsLabelVisible] = useState(false)
  
    const showLabel = () => setIsLabelVisible(true)
    const hideLabel = () => setIsLabelVisible(false)

    const returnIcon = (icon, lable) => {
        return (
            <div className="container_icon_product" onMouseOver={showLabel} onMouseOut={hideLabel} key={`prodicon${index}`}>
                <label className={isLabelVisible ? "" : "hidden_allproducts"}>{t(`${lable}`)}</label>
                {icon}
            </div> 
        ) 
    }

    switch(icon.icon) {
        case 'Alcohol':
            return returnIcon(<Alcohol />, icon.label)   
        case 'Ambient':
            return returnIcon(<Ambient />, icon.label)
        case 'Hallal':
            return returnIcon(<Hallal />, icon.label)
        case 'Frozen':
            return returnIcon(<Frozen/>,icon.label)
        case 'Kosher':
            return returnIcon(<Kosher />, icon.label)
        case 'Chilled':
            return returnIcon(<Chilled/>,icon.label)
        case 'Fish':
            return returnIcon(<Fish/>,icon.label)
        default:
            return null
    }
}