import React from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as Calendar } from '../assets/svg/calendar.svg'
import {t} from "i18next";
import '../assets/css/datePicker.css'

const days = [t('niki.common.Sunday') || 'Sunday', t('niki.common.Monday') || 'Monday', t('niki.common.Tuesday') || 'Tuesday', t('niki.common.Wednesday') || 'Wednesday', t('niki.common.Thursday') || 'Thursday', t('niki.common.Friday') || 'Friday', t('niki.common.Saturday') || 'Saturday']
const months = [t('niki.common.January') || 'January', t('niki.common.February') || 'February', t('niki.common.March') || 'March', t('niki.common.April') || 'April', t('niki.common.May') || 'May', t('niki.common.June') || 'June', t('niki.common.July') || 'July', t('niki.common.August') || 'August', t('niki.common.September') || 'September', t('niki.common.October') || 'October', t('niki.common.November') || 'November', t('niki.common.December') || 'December']
    
let today = new Date();
let milliseconds = today.getTime();

const CustomDatePicker = (props) => {
    const {selected, setStartDate, calendarStartDay, filterDate, noLaterTodate, tomorow, disabled, dayWeek} = props;

    const isWeekday = (date) => {
        const day = date.getDay();
        return day !== 0 && day !== 6;
    };

    const locale = {
        localize: {
            day: n => days[n].charAt(0),
            month: n => months[n]
        },
        formatLong: {
            date: () => 'dd.mm.yyyy'
        }
    }

    const CustomInput = React.forwardRef((props, ref) => {
        const {value, onClick} = props
        return (
            <div onClick={onClick} ref={ref} className={disabled ? ['date_picker_custom_input', 'date_picker_disabled'].join(' ') : 'date_picker_custom_input'}>
                <button>
                    {value}
                </button>
                <Calendar/>
            </div>
        );
    });

    const onSetstartDate = (e) => {
        const selelect = new Date(e)
        if (noLaterTodate) {
            milliseconds - 86400000 <= selelect.getTime() ?  setStartDate(e) : setStartDate(today)
        } else if (tomorow && calendarStartDay) {
            milliseconds + 86400000 * calendarStartDay <= selelect.getTime() ? setStartDate(e) : setStartDate(today.setTime(milliseconds + 86400000 * calendarStartDay))
        } else if (tomorow) {
            milliseconds <= selelect.getTime() ? setStartDate(e) : setStartDate(today.setTime(milliseconds + 86400000))
        } else {
            setStartDate(e)
        } 
    } 

    return (<div className='date_picker'>
        <div>{days[dayWeek]},</div>
            <DatePicker
                filterDate={filterDate ? isWeekday : null}
                customInput={<CustomInput />}
                calendarStartDay={1}
                dateFormat="dd.MM.yyyy"
                selected={selected}
                disabled={disabled ? disabled : false}
                onChange={(date) => onSetstartDate(date)}
                locale={locale}
            />
        </div>
    )
}

export default CustomDatePicker;