import { NavLink } from 'react-router-dom'
import { t } from 'i18next'
import { useDispatch, useSelector } from 'react-redux'

import { addCartProduct } from '../reduxToolkit/cartSlice'
import { LangFix, PriceFormat, HideLoader, ShowLoader, getCartProducts } from '../functions'
import defaultImage from '../assets/images/placeholder.jpg'
import { priceLineID } from '../config'
import { ReactComponent as Cart } from '../assets/svg/ppcart.svg'
import { getRequestOptions, includeForProduct, returnErrorDuringAddProductToCart } from '../helpers/apiHelper'
import { ProductQtyChangeButtons } from '../components/MyShoppingCart/ProductQtyChangeButtons'

export const SearchSingleProduct = props => {
    const dispatch = useDispatch()

    const selectedLanguage = LangFix(localStorage.getItem('locale'))

    const productsInCart = useSelector(state => state.cartContent.productIDs)

    const productID = props.id
    const productMatchcode = props.matchcode
    
    const productName = props?.itemDescriptions[selectedLanguage]?.short_description
        ? props.itemDescriptions[selectedLanguage].short_description
        : props.itemDescriptions['en'].short_description
            ? props.itemDescriptions['en'].short_description
            : productMatchcode
    
    const producFamily = props.family
        ? props.family.slice(props.family.indexOf('.') + 1, props.family.includes(',')
            ? props.family.indexOf(',')
            : props.family.length)
        : ''
    const productImage = props.attachment || defaultImage

    const productLink = `/productpage/${productID}/`
    
    const productQtyInBox = parseInt(props.itemUnitBarcodeByUnitCodeBox?.coefficient)
    const ProductAvailable = parseInt(props.itemStocks[0]?.available) || 0

    let prodPriceOld = 0
    let prodPrice = props?.itemPrices[priceLineID]?.unit_price
        ? parseFloat(props.itemPrices[priceLineID].unit_price)
        : 0
    let prodGross = props.itemPrices[priceLineID].tax_included_price ? parseFloat(props.itemPrices[priceLineID].tax_included_price) : 0
    if (props.itemDiscounts && props.itemDiscounts[priceLineID]) {
        prodPriceOld = prodPrice
        prodPrice = props.itemDiscounts[priceLineID].unit_price
        prodGross = props.itemDiscounts[priceLineID].tax_included_price
    }

    let showStock = true
    const ProductUni = (props.main_unit_code_translations[selectedLanguage]) || props.main_unit_code || ''
    const notShowQuantityt = (parseInt(localStorage.getItem('show.stock')) === 0 || parseInt(localStorage.getItem('show.stock')) === 1)
    
    let stockStatus = 'outofstock'
    let stockText = `${t('niki.b2b.product.outofstock')}`
    if (parseInt(localStorage.getItem('show.stock')) === 0) {
        showStock = false
    } else if (ProductAvailable > 20) {
        stockStatus = 'instock'
        stockText = notShowQuantityt
            ? t('niki.b2b.allproducts.instock')
            : `${ `${ProductAvailable}`.length > 4 ? (`${ProductAvailable}`.slice(0,`${ProductAvailable}`.length-3)+"K+") : ProductAvailable} ${ProductUni?.includes('(')
                ? ProductUni?.slice(0, ProductUni?.indexOf('('))
                : ProductUni
             }`
    }else if (ProductAvailable > 0) {
        stockStatus = 'low'
        stockText = `${t('niki.b2b.product.stocklow')}`
    }
    const cartdata = {
        name: productName,
    }
    const PutToCart = e => {
        //ShowCartOverlay()
        if (localStorage.getItem('cart.uuid')) {
            const uuid = localStorage.getItem('cart.uuid')
            ShowLoader()
            const requestOptionsCart = getRequestOptions('POST', null, 'application/json')
            requestOptionsCart.body = JSON.stringify({
                cart_header_uuid: uuid,
                item_id: productID,
                matchcode: productMatchcode,
                variation_id: '3',
                qty_in_box: productQtyInBox.toString(),
                qty: productQtyInBox.toString(),
                unit_price: prodPrice.toString(),
                tax_included_price: prodGross
            })
            
            fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-details?include=${includeForProduct}}&realKey=true&showVat=true&source_price_id[]=${priceLineID}`,requestOptionsCart)
            .then(response => response.json())
            .then(json => {
                if (json?.message) {
                    returnErrorDuringAddProductToCart(json.message, dispatch)
                    props.clearAllAndHideAll()
                } else if (json.data) {
                    const cartData = getCartProducts(json.data)
                    dispatch(addCartProduct(cartData))
                }
            })
            .catch(error => returnErrorDuringAddProductToCart(error, dispatch))
            .finally(() => HideLoader())
        } else {
            let detaildata = []
            detaildata[0] = {
                matchcode: props.matchcode,
                variation_id: '1',
                qty: productQtyInBox.toString(),
                unit_price: prodPrice.toString(),
                tax_included_price: prodGross.toString(),
                qty_in_box: productQtyInBox.toString(),
                item_id: productID,
            }
            const requestOptionsCart = getRequestOptions('POST', null, 'application/json')
            requestOptionsCart.body = JSON.stringify({
                company_id: props.company_id,
                customer_account_id: localStorage.getItem('account.entity.id'),
                status_id: 'J8RvMwowm7k2pNOr',
                details: detaildata,
                realKey: '1',
            })
            
            ShowLoader()
            fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-headers`,requestOptionsCart)
            .then(response => response.json())
            .then(json => {
                if (json.data) {
                    localStorage.setItem('cart.id',json.data.id)
                    localStorage.setItem('cart.id.real',json.data.id_real)
                    localStorage.setItem('cart.uuid',json.data.uuid)
                    cartdata.id = json.data.id
                    cartdata.name = 'Product Name'
                    cartdata.qty = productQtyInBox
                    cartdata.qtyinbox = productQtyInBox
                    cartdata.unitcode = ProductUni
                    cartdata.price = prodPrice
                    cartdata.productid = props.id
                    cartdata.itemid = props.item_id
                    cartdata.productitemid = props.product_item_id
                    dispatch(addCartProduct(cartdata))
                }      
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
        }
    }

    return (
        <div className="wrapper__search_single_product">
            <div className="search_single_product">
                <NavLink to={productLink} onClick={() => props.clearAllAndHideAll()} className="search_single_product__image">
                    <img src={productImage} alt={productMatchcode} loading="lazy" />
                </NavLink>
                <div>
                    <div className="search_single_product__name_price">
                        <NavLink to={productLink} onClick={() => props.clearAllAndHideAll()} className="search_single_product__name">{productName}</NavLink>
                        {prodPriceOld > 0 ? <span className="search_form__results_hit__price__discount">-{Math.round((prodPriceOld - prodPrice) / prodPriceOld * 100)}%</span> : null}
                    </div>
                    <div className="search_single_product__info">
                        <span>{props.source_item_id || ''}</span>
                        <div className="uni_area"><p>{t('niki.b2b.cartflow.box')} {productQtyInBox}</p></div>
                    </div>
                    <div className="search_single_product__info">
                        <div className="uni_area"><p>{producFamily}</p></div>
                        {showStock && <span className={stockStatus}><span className="search_single_product__info__stock"></span>{stockText}</span>}
                    </div>
                </div>
                <div className="wrapper_price_and_basket">
                    <div className={["search_single_product__price",prodPriceOld > 0 && 'sell_in_search'].join(' ')}>{PriceFormat(prodPrice)}</div>
                    {productsInCart.includes(productID)
                        ? <button></button>
                        : <button className={["buttons_area__button cart", "buttons_cart__button_search"].join(' ')} onClick={PutToCart}><Cart /></button>
                    }
                </div>
            </div>
            <div className="quick_add_change_product_data__search">
                {productsInCart.includes(productID)
                    && <ProductQtyChangeButtons productid={productID} qty={props.prodQtyCart} qtyinbox={productQtyInBox} sale_unit_code={props.sale_unit_code}/>
                }
            </div>
        </div>
    )
}