import React, { useEffect, useState } from "react"
import { NavLink } from 'react-router-dom'
import { t } from "i18next"
import { FixCompanyName, getBannerDataLogin, ShowLoader, HideLoader } from "../functions.js"
import { LoginHeader } from "./LoginHeader"

import "../assets/css/login.css"

export const PhoneRecovery = () => {
    const [vatID, setVatID] = useState('')
    const [clientID, setClientID] = useState('')
    const [emptyVatID, setEmptyVatID] = useState(false)
    const [emptyClientID, setEmptyClientID] = useState(false)
    const [bannerOne, setBannerOne] = useState({})

    const RecoverSMS = e => {
        e.preventDefault()
        vatID.trim().length === 0 ? setEmptyVatID(true) : setEmptyVatID(false)
        clientID.trim().length === 0 ? setEmptyClientID(true) : setEmptyClientID(false)
        if (vatID.trim().length > 0 && clientID.trim().length > 0) {
            ShowLoader()
            setInterval(() => HideLoader(), 2000)
        }
    }

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem('banners_data'))
        data && data.forEach(e => {
            e.content_subtype_id === 'O5D1YKnGOndeJ9qv' && e.contentType?.data?.real_id === 1 && setBannerOne(getBannerDataLogin(e))
        })
    }, [])

    return (
        <>
            <div className="login__wrapper">
                <LoginHeader />
                <form autoCapitalize="off" autoComplete="off" autoCorrect="off" className="login__content" onSubmit={RecoverSMS}>
                    <div className="login__header__title">{t('niki.b2b.login.recovermobileaccessfor')}<br />{FixCompanyName(t('niki.b2b.login.monolithunitedkingdom'))}</div>
                    {emptyVatID && <div className="alert alert-danger">{t('niki.b2b.login.emptyvatid')}</div>}
                    {emptyClientID && <div className="alert alert-danger">{t('niki.b2b.login.emptyclientid')}</div>}
                    <div className="marginbottom10">{t('niki.b2b.login.inputvatid')}</div>
                    <div className="marginbottom10">
                        <input
                            type="text"
                            className="form-control"
                            placeholder={t('niki.b2b.login.vatid')}
                            value={vatID}
                            onChange={e => setVatID(e.target.value)}
                        />
                    </div>
                    <div className="marginbottom20">
                        <input
                            type="text"
                            className="form-control"
                            placeholder={t('niki.b2b.login.clientid')}
                            value={clientID}
                            onChange={e => setClientID(e.target.value)}
                        />
                    </div>
                    <div className="marginbottom10">
                        <button type="submit" className="btn">{t('niki.b2b.login.recovermobilenumber')}</button>
                    </div>
                    <div className="marginbottom50">
                        <NavLink to="/" className="btn">{t('niki.b2b.recovery_modal.back_to_login')}</NavLink>
                    </div>
                </form>
                <a href={bannerOne.url} target={bannerOne.target} rel="noopener noreferrer" className="login__banner"><img src={bannerOne.image} alt={bannerOne.title} /></a>
            </div>
            <div className="loader"></div>
        </>
    )
}