import { useState } from "react"
import { ShowLoader, HideLoader } from '../functions'
import { getRequestOptions } from '../helpers/apiHelper'

import './../assets/css/allproducts.css'

import { ReactComponent as HeartWhite } from './../assets/svg/heartwhite.svg'
import { ReactComponent as HeartRed } from './../assets/svg/heartred.svg'

export const FavoritesButton = ({ productId, isFavorites, setClickFavorites, details = false }) => {

    const [isFavorite, setIsFavorite] = useState(localStorage.getItem('favorites') && localStorage.getItem('favorites').split(',').includes(productId))
    
    const FavoriteButtonClick = e => {
        const thisElement = e.target
        const favoritesLocal = localStorage.getItem('favorites')
        if (thisElement.classList.contains('true')) {
            ShowLoader()
            fetch(`${process.env.REACT_APP_API_DOMAIN}/items/${productId}/favorites`,getRequestOptions('DELETE'))
            .then(response => {
                if (response.ok) {
                    thisElement.classList.remove('true')
                    thisElement.classList.add('false')
                    response.ok && setIsFavorite(false)
                    setClickFavorites && setClickFavorites(prev => !prev)
                    localStorage.setItem('favorites', favoritesLocal.split(',').filter(p => p !== productId).join(','))
                }
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
        } else {
            ShowLoader()
            fetch(`${process.env.REACT_APP_API_DOMAIN}/items/${productId}/favorites`,getRequestOptions('POST'))
            .then(response => {
                if (response.ok) {
                    response.ok && setIsFavorite(true)
                    if (localStorage.getItem('favorites')) {
                        let tempArr = localStorage.getItem('favorites').split(',')
                        tempArr.push(productId)
                        localStorage.setItem('favorites',tempArr.join(','))
                    } else {
                        localStorage.setItem('favorites',productId)
                    }
                    thisElement.classList.remove('false')
                    thisElement.classList.add('true')
                    setClickFavorites && setClickFavorites(prev => !prev)
                }
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
        }
    }

    return (
        <button
            className={[`buttons_area__button`, details && 'button_favorite_details', isFavorites || isFavorite].join(' ')}
                onClick={FavoriteButtonClick}
            >
            <HeartWhite className="heartwhite" />
            {isFavorite && <HeartRed className="heartred" />}
        </button>
                   
    )
}