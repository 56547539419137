import React, { useEffect, useRef, useState } from "react"
import { NavLink } from 'react-router-dom'
import { t } from "i18next"
import { companyCountryShort, companyUrlAddress } from '../config.js'
import { FixCompanyName, getBannerDataLogin, ShowLoader, HideLoader, generateLocatilization } from "../functions.js"

import { LangSelector } from "./LangSelector"
import { LoginHeader } from "./LoginHeader"

import { AlertPopup } from '../components/AlertPopup'
import { getRequestOptionsNoAuth, fetchDataPhoneOrEmail, getBanners } from '../helpers/apiHelper'

import "../assets/css/login.css"

import { ReactComponent as LoginSubmit } from '../assets/svg/loginpagesubmit.svg'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export const LoginPhone = () => {
    const [phoneNumber2, setPhoneNumber2] = useState('')
    const [countryCode, setCountryCode] = useState('44')
    const [phoneStatus, setPhoneStatus] = useState('good')
    const [bannerOne, setBannerOne] = useState({})
    const [isPhoneEmptyAlert, setIsPhoneEmptyAlert] = useState(false)
    const [isCountryCodeEmptyAlert, setIsCountryCodeEmptyAlert] = useState(false)
    
    const phoneEl = useRef(null)

    const SubmitPhone = e => {
        e.preventDefault()
        phoneNumber2.length === 0 && setIsPhoneEmptyAlert(true)
        countryCode.length === 0 && setIsCountryCodeEmptyAlert(true)
        if (phoneNumber2.length > 0 && countryCode.length > 0) {
            const requestOptions = getRequestOptionsNoAuth('POST', null, 'application/json')
            requestOptions.body = JSON.stringify({  phone: `+${countryCode}${phoneNumber2}` })
            
            ShowLoader()
            fetchDataPhoneOrEmail(`${process.env.REACT_APP_API_DOMAIN}/clients/web/phone`,requestOptions)
            .then(response => {
                if (response) {
                    setPhoneStatus('phonecorrect')
                    localStorage.setItem('account.user.phone',`+${countryCode}${phoneNumber2}`)
                    window.location.href = '/enter-code/?from=SMS'
                } else {
                    setPhoneStatus('usernotfound')
                }
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
        }
    }
    const CountryChange = phone => {
        setCountryCode(phone.trim())
        phoneEl.current.focus()
    }

    useEffect(() => {
        ShowLoader()
        getBanners().then(json => {
            json.data && json.data.forEach(e => {
                e.content_subtype_id === 'O5D1YKnGOndeJ9qv' && e.contentType?.data?.real_id === 1 && setBannerOne(getBannerDataLogin(e))
            })
            json.data && localStorage.setItem('banners_data', JSON.stringify(json.data))
            HideLoader()
        })
    }, [])
   
    return (
        <>
            <div className="login__wrapper">
                <LoginHeader />
                <form
                    autoComplete="on"
                    className="login__content"
                    onSubmit={SubmitPhone}
                >
                    <div className="login__header__title">{t('niki.b2b.login.into')}<br />{FixCompanyName(t('niki.b2b.login.monolithunitedkingdom'))}</div>
                    <div className="dont_have_an_account">{t('niki.b2b.login.donthave')} <a href={companyUrlAddress} target="_blank" rel="noopener noreferrer">{t('niki.b2b.login.signup')}</a></div>
                    {phoneStatus === 'usernotfound' && <div className="alert alert-danger">{t('niki.b2b.login.numberisnotcorrect')}.</div>}
                    {phoneStatus === 'phonecorrect' && <>
                        <div className="alert alert-wait">{t('niki.b2b.login.didntreceivesms')}</div>
                        <div className="alert alert-success">{t('niki.b2b.login.passwordsentphone')}</div>
                    </>}
                    <div className="email_input">
                        <PhoneInput
                            localization={generateLocatilization()}
                            country={companyCountryShort || 'gb'}
                            className = "country_input"
                            preferredCountries={['it','de','fr','gr','ru','es','pt','gb']}
                            onChange={CountryChange}
                            value=""
                            enableSearch="true"
                        />
                        <input
                            type="text"
                            pattern="[0-9]*"
                            inputMode="numeric"
                            className="form-control phone_input"
                            placeholder={t('niki.b2b.login.mobilephonenumber')}
                            ref={phoneEl}
                            autoComplete="on"
                            onChange={e => setPhoneNumber2(e.target.value.trim())}
                            autoFocus
                        />
                        <div className="phone_code">+{countryCode}</div>
                    </div>
                    <div className="email_submit"><button className="btn" type="submit">{t('niki.b2b.login.login')}<LoginSubmit /></button></div>
                    <div>{t('niki.b2b.login.forgotnumber')} <NavLink to="/recover-sms/">{t('niki.b2b.login.recover')}</NavLink></div>
                    <ul className="privacy_policy__disclosures">
                        <li><NavLink to="/privacy-policy/">{t('niki.b2b.footer.privacypolicy')}</NavLink></li>
                        <li><NavLink to="/mandatory-disclosures/">{t('niki.b2b.login.disclosures')}</NavLink></li>
                    </ul>
                    <div className="prefer_login_by_email">{t('niki.b2b.login.preferloginemail')} <NavLink to="/login-email/">{t('niki.b2b.login.now')}</NavLink></div>
                    <LangSelector />
                </form>
                <a href={bannerOne.url} target={bannerOne.target} rel="noopener noreferrer" className="login__banner"><img src={bannerOne.image} alt={bannerOne.title} /></a>
            </div>
            <div className="loader"></div>
            {isPhoneEmptyAlert && <AlertPopup text={t('niki.b2b.login.phoneempty')} closeAlert={() => setIsPhoneEmptyAlert(false)}/> }
            {isCountryCodeEmptyAlert && <AlertPopup text={t('niki.b2b.login.countrycodeempty')} closeAlert={() => setIsCountryCodeEmptyAlert(false)}/>}
        </>
    )
}