import { xCompanyID, meilisearchUrl, xTenant } from '../config'
import { ShowLoader, HideLoader } from '../functions.js'
import { setIsOpenNoCartAlert, setIsOpenProductNotFoundAlert } from '../reduxToolkit/allproduct'

export const includeForProduct = 'item,item.itemCategories,item.entityAccount,item.itemDescriptions,item.attachments,item.itemPrices,item.itemDiscounts,item.itemKinds,item.itemStocks,item.itemFamily'
const LangFix = e => e === 'gb' ? 'en' : e

export const getRequestOptions = (method = 'GET', accept, contentType) => {
    return {
        method: method,
        headers: {
            'Accept': accept ? accept : 'application/json',
            'Accept-Language': LangFix(localStorage.getItem('locale')),
            'Content-Type': contentType ? contentType : 'application/x-www-form-urlencoded',
            'X-Tenant': xTenant,
            'X-CompanyID': xCompanyID,
            'Authorization': `Bearer ${localStorage.getItem('access.token')}`
        }
    }
}

export const getRequestOptionsNoAuth = (method = 'GET', accept, contentType) => {
    return {
        method: method,
        headers: {
            'Accept': accept ? accept : 'application/json',
            'Accept-Language': LangFix(localStorage.getItem('locale')),
            'Content-Type': contentType ? contentType : 'application/x-www-form-urlencoded',
            'X-Tenant': xTenant,
            'X-CompanyID': xCompanyID,
        }
    }
}

export const fetchDataPhoneOrEmail = async (url, requestOptions) => {
    const response = await fetch(url, requestOptions)
    return response.ok
}

export const getBanners = async (item_segmentations = '') => {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/contents?include=contentType,attachments,items${item_segmentations}`, getRequestOptionsNoAuth())
    const json = await response.json()
    return json
}

export const deleteProductFormCart = (productId) => {
    ShowLoader()
    fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-details/${productId}`, getRequestOptions('DELETE'))
        .then(response => {
            // console.log(response)
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
}

export const updateProductInCartPost = async (productId, qty) => {
    const requestOptions = getRequestOptions('POST', null, 'application/json')
    requestOptions.body = JSON.stringify({ qty: qty })

    ShowLoader()
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-details/${productId}`, requestOptions)
    const json = await response.json()
    HideLoader()
    return json
}

export const getProductFormSearch = async (codes) => {
    const filterInsert = `(barcode=${codes} OR source_item_id=${codes}) AND company_id=${xCompanyID}`
    const response = await fetch(`${meilisearchUrl}/indexes/${getMeilisearchIndexName("items")}/search?filter=${encodeURIComponent(filterInsert)}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-CompanyID': xCompanyID,
            'Authorization': `Bearer ${process.env.REACT_APP_MEILISEARCH_SECRET_KEY}`
        }
    })
    const json = await response.json()
    return json
}

export const deleteCartsItem = async cartItemID => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/cart-headers/${cartItemID}`
    const response = await fetch(URL, getRequestOptions('DELETE'))
    return response
}

export const getAllCartsData = async (url) => {
    const response = await fetch(url, getRequestOptions())
    const json = await response.json()
    return json
}

export const getAccountBlocked = async () => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/entity-account-check/${localStorage.getItem('account.entity.id')}`
    const response = await fetch(URL, getRequestOptions())
    const json = await response.json()
    // return {blocked: true}
    return json
}

export const returnErrorDuringAddProductToCart = (error, dispatch) => {
    if (localStorage.getItem('cart.uuid')) {
        getAllCartsData(`${process.env.REACT_APP_API_DOMAIN}/cart-headers/${localStorage.getItem('account.entity.id.real')}`)
            .then(json => {
                let existCart = false
                json.data && json.data.forEach(e => {
                    existCart = e.uuid === localStorage.getItem('cart.uuid') && true
                })
                existCart
                    ? dispatch(setIsOpenProductNotFoundAlert(true))
                    : dispatch(setIsOpenNoCartAlert(true))
            })
            .catch(error => {
                dispatch(setIsOpenProductNotFoundAlert(true))
                console.error(error)
            })
    } else {
        dispatch(setIsOpenProductNotFoundAlert(true))
    }
    console.error(error)
}

export const getMeilisearchIndexName = (indexName) => `${xTenant}_${xCompanyID}_${indexName}`

export const updateMarketingCampaignIds = () => {
    const entityID = localStorage.getItem('account.entity.id.real')
    fetch(`${process.env.REACT_APP_API_DOMAIN}/entity-account/${entityID}?include=company,address,address.contact,user,company.socialValues&realKey=1&type_id=8&itemSegmentations=true`, getRequestOptions())
        .then(response => response.json())
        .then(json => {
            if (json.data) {
                localStorage.setItem('marketingCampaignIds', JSON.stringify(json.data.marketingCampaignIds))
            }
        })
        .catch(error => console.error(error))
}