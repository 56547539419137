import React, { useRef, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import { t } from "i18next"

import { PriceFormat, GetHistoryData, returnStockStatusText } from '../../functions.js'
import { deleteProductFormCart } from '../../helpers/apiHelper'
import { deleteCartProduct } from "../../reduxToolkit/cartSlice"

import { ProductQtyChangeButtons } from './ProductQtyChangeButtons'
import { OrderHistoryPopup } from '../OrderHistoryPopup'

import '../../assets/css/shoppingcart.css'

import { ReactComponent as History } from '../../assets/svg/schistory.svg'

export const ProductListItem = ({e, i, showNumButton}) => {

    const dispatch = useDispatch()

    const cartDetailsIDs = useSelector(state => state.cartContent.cartDetailsIDs)
    const cartProductData = useSelector(state => state.cartContent.cartProductData)
    const cartContent = useSelector(state => state.cartContent.cartContent)

    const lastElementRef = useRef(null)

    const [isLastElement, setLastElement] = useState(false)
    const [isOpenHistoryProduct, setIsOpenHistoryProduct] = useState(false)
   
    const ReturnStock = (StockCount, UnitCode) => {
        let { stockStatus, stockText } = returnStockStatusText(StockCount,UnitCode)
        return <div className={stockStatus}><span className={[`shopping_cart__stock`,stockStatus].join(' ')}></span>{stockText}</div>
    }

    const ifInputValueEmpty = (value) => {
        if (!value.qty) {
            dispatch(deleteCartProduct(cartDetailsIDs[value.productid]))
            deleteProductFormCart(cartDetailsIDs[value.productid])
        }
    }

    const ExplandCollapseCartDetail = e => {
        ifInputValueEmpty(e.target.dataset)
        const WhatExpland = e.target.parentNode.parentNode.parentNode.parentNode.parentNode
        WhatExpland.classList.toggle('active')
    }

    const ShowBlackOverlay = e => {
        const WhatExpland = e.target.parentNode.parentNode.parentNode
        WhatExpland.classList.add('show_black_overlay')
    }
    const HideBlackOverlay = e => {
        if (e.target.dataset.qty === 0 || e.target.dataset.qty === "0") {
            dispatch(deleteCartProduct(cartDetailsIDs[e.target.dataset.productid]))
            deleteProductFormCart(cartDetailsIDs[e.target.dataset.productid])
        }
        ifInputValueEmpty(e.target.dataset)
        const WhatHide = e.target.parentNode.parentNode.parentNode.parentNode
        WhatHide.classList.remove('show_black_overlay')
    }
   
    const ShowProdHistory = e => {
        setIsOpenHistoryProduct(true)
    }
  
    useEffect(() => {
        if (lastElementRef.current && cartContent.length > 0 && isLastElement) {
            lastElementRef.current.scrollIntoView({ behavior: 'smooth' })
            setLastElement(false)
        }
    }, [cartContent, isLastElement])

    const onProductClick = () => {
        localStorage.setItem('url.back', `${window.location.pathname}`)
    }

    return (
        <div key={`cartcontentlistitem${i}`}>
            <div className="shopping_cart__list__item">
                <div className="shopping_cart__list__item__first">
                    <NavLink to={`/productpage/${e.productid}/`} onClick={onProductClick} className="shopping_cart__list__item__first__image">
                        <img src={e.image} alt={e.name} loading="lazy" />
                    </NavLink>
                    <div>
                        <NavLink to={`/productpage/${e.productid}/`} onClick={onProductClick} className="shopping_cart__list__item__first__product_name">{e.name}</NavLink>
                        <div className="shopping_cart__history_price_expland">
                            {GetHistoryData(e.productid).length > 0
                                ? <button onClick={ShowProdHistory} data-id={e.productid}><History /></button>
                                : <span></span>
                            }
                            <div>{Boolean(e.priceold) && parseFloat(e.priceold) !== parseFloat(e.price) && <span className="shopping_cart__discount">-{Math.round((e.priceold-e.price)/e.priceold*100)}%</span>}</div>
                            <div className="shopping_cart__total">{PriceFormat(parseInt(e.qty) * parseFloat(e.price))}</div>
                            <div className="shopping_cart__incart">{`${e.qty}`.length > 4 && Number.isInteger(e.qty) ? (`${e.qty}`.slice(0,`${e.qty}`.length-3)+"K+") : e.qty}</div>
                            <div><button className="shopping_cart__expland" data-productid={e.productid} data-qty={e.qty} onClick={ExplandCollapseCartDetail}></button></div>
                        </div>
                    </div>
                </div>
                <div className="shopping_cart__list__item__second">
                    <div>
                        <ul className="shopping_cart__list__item__second__list">
                            {e.group && <li>{t('niki.b2b.shoppingcart.group')}: {e.group}</li>}
                            {e.type && <li>{t('niki.b2b.feedback.type')}: {e.type}</li>}
                            <li>{t('niki.b2b.product.in_box')}: {e.qtyinbox}</li>
                        </ul>
                    </div>
                    <div>
                        <ul className="shopping_cart__list__item__second__list">
                            <li>{t('niki.b2b.product.unitprice')}: {PriceFormat(e.price)}</li>
                            <li>{t('niki.b2b.product.vat')}: {Math.round(e.vat/e.price*100)}%</li>
                            <li>{ReturnStock(e.instock,e.unitcode)}</li>
                        </ul>
                    </div>
                        {showNumButton && <div className="shopping_cart__list__item__second__last_column">
                            <button className="shopping_cart__num_button" onClick={ShowBlackOverlay}>{e.qty}</button>
                        </div>}
                    <div className="shopping_cart__list__item__second__overlay">
                        <div className="shopping_cart__list__item__second__overlay__info">
                            <div><strong>{t('niki.b2b.product.unitprice')}:</strong> {PriceFormat(e.price)}</div>
                            <div>{t('niki.b2b.cartflow.box')} {e.qtyinbox}</div>
                        </div>
                        <div className="wrappe_overlay_buttons_input"> 
                            <ProductQtyChangeButtons productid={e.productid} qty={e.qty} qtyinbox={e.qtyinbox} sale_unit_code={e.sale_unit_code}/>
                        </div>
                        <div>    
                            <button className="close_black_overlay" data-productid={e.productid} data-qty={e.qty} onClick={HideBlackOverlay}></button>
                        </div>
                    </div>
                </div>
            </div>
            {GetHistoryData(e.productid).length > 0 && isOpenHistoryProduct &&
                    <OrderHistoryPopup
                    matchcode={cartProductData[e.productid].name}
                    imgsrc={cartProductData[e.productid].image}
                    sale_unit_code={cartProductData[e.productid].unitcode}
                    qty_in_box={cartProductData[e.productid].qtyinbox}
                    item_id={cartProductData[e.productid].itemid}    
                    product_id={e.productid}
                    setIsOpenHistoryProduct={setIsOpenHistoryProduct}
                />
            }
        </div>
    )
}