import React, { useRef, useEffect, useState }  from 'react'

import './../assets/css/shoppingcart.css'

const SortingData = [
    {name: 'datetimeadded', title: 'Date and Time Added'},
    {name: 'brand_asc', title: 'Brand ASC'},
    {name: 'brand_desc', title: 'Brand DESC'},
    {name: 'family_asc', title: 'Family - ASC'},
    {name: 'family_desc', title: 'Family - DESC'},
    {name: 'type_asc', title: 'Type - ASC'},
    {name: 'type_desc', title: 'Type - DESC'},
]

export const SortingProductsData = ({ isSortingProductsClose, setIsSortingProducts, selectSortByItem, setSelectSortByItem }) => {

    const [isClose, setIsClose] = useState(false)

    const sortingEl = useRef(null)
    
    useEffect(() => {
        let timer;

        clearTimeout(timer);
        timer = setTimeout(() => {
            sortingEl.current.classList.add('active')
        }, 200); 
           
        return () => clearTimeout(timer)
    }, [])
    
    useEffect(() => {
        let timer;
        clearTimeout(timer);
        if (isSortingProductsClose) { 
            sortingEl.current.classList.remove('active')
        }
        if (isClose || isSortingProductsClose) {
            timer = setTimeout(() => {
                setIsSortingProducts(false)
            }, 200);
        }
           
        return () => clearTimeout(timer)
        // eslint-disable-next-line
    }, [isClose, isSortingProductsClose])

    const hideSortPanel = () => {
        sortingEl.current.classList.remove('active')
        setIsClose(true)
    }

    const changeCartSorting = e => {
        const valueSort = e.target.dataset.value
        setSelectSortByItem(valueSort)
        sortingEl.current.classList.remove('active')
        setIsClose(true)
    }
   
    return (
        <div className="shopping_cart__sorting_panel__wrapper" ref={sortingEl}>
            <ul className="shopping_cart__sorting_panel">
                {SortingData.map((e, i) => <li className={selectSortByItem === e.name && 'select_shopping_cart__sorting_panel_item'} key={i} onClick={changeCartSorting} data-value={e.name}>{e.title}</li>)}
            </ul>
            <div className="shopping_cart__sorting_panel__overlay" onClick={hideSortPanel}></div>
        </div>
    )
}