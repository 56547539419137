import React, { useEffect, useState }  from 'react'
import { useSearchParams, NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { t } from "i18next"
import { priceLineID } from '../config'
import { getRequestOptions } from '../helpers/apiHelper'
import { ShowLoader, HideLoader, setCartIdsToStorage, scrollTop } from "../functions"
import { setUuidCart } from '../reduxToolkit/cartSlice'


import { BreadCrumbs } from '../components/BreadCrumbs'
import { TwoContacts } from '../components/TwoContacts'
import LoadableImage from "../components/LoadableImage/LoadableImage"

import { ReactComponent as Printer } from './../assets/svg/typrinter.svg'
import { ReactComponent as User } from './../assets/svg/tyuser.svg'
import { ReactComponent as Orders } from './../assets/svg/tyorders.svg'
import { ReactComponent as ShoppingCarts } from './../assets/svg/tyshoppingcarts.svg'

import './../assets/css/thankyou.css'

const LoadCartsData = async () => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/cart-headers/${localStorage.getItem('account.entity.id')}?include=status,company,cartDetails,items&realKey=1&orderBy=id&sortedBy=desc&showVat=true`
    const response = await fetch(URL,getRequestOptions())
    const json = await response.json()
    return json
}
const LoadOrderData = async orderID => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/documents/${orderID}?include=details,status&realKey=true&showVat=true&source_price_id[]=${priceLineID}&warehouse_id=${localStorage.getItem('warehouse.id')}`
    const responce = await fetch(URL,getRequestOptions())
    const json = await responce.json()
    return json
}

const ThankYouContent = props => {

    const dispatch = useDispatch()

    const cart_id = localStorage.getItem('cart.id')
    const cart_uuid = localStorage.getItem('cart.uuid')
    let orderIDs = []
    if (props.orderIDs.length > 0) orderIDs = props.orderIDs

    const [numberCarts, setNumberCarts] = useState(0)
    const [orderNumber, setOrderNumber] = useState([])
    const [cart, setCart] = useState(null)

    useEffect(() => {
        return (() => {
            if (!cart_id && !cart_uuid && cart) {
                setCartIdsToStorage(cart.uuid, cart.real_id, cart.id)
                dispatch(setUuidCart(cart.uuid))
            }
        })
        // eslint-disable-next-line
    }, [cart])

    useEffect(() => {
        scrollTop()
        try {
            ShowLoader()
            LoadCartsData()
                .then(json => {
                    if (json.data) {
                        setNumberCarts(json.data.length)
                        setCart(json.data[0])
                    }
                })
        } catch (error) {
            console.error(error)
        } finally {
            HideLoader()
        }
 
        const getOrderid = async (orderId) => {
            try {
                ShowLoader()
                const result = await LoadOrderData(orderId)
                result.data.number && setOrderNumber(prev => [...prev, { order_number: result.data.number, order_id: orderId }])
            } catch(error) {
                console.error(error)
            } finally {
                HideLoader()
            }
        }
        
        orderIDs.forEach(uuid => getOrderid(uuid)) 
       // eslint-disable-next-line
    }, [])

    const OpenPDF = (id) => {
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/documents/${id}/print?include=details`,getRequestOptions('GET', 'text/plain'))
        .then(response => response.text())
        .then(url => window.open(`http://${url}`,'_blank','noreferrer'))
        .catch(error => console.error(error))
        .finally(() => HideLoader())
    }

    return (
        <>
            <BreadCrumbs itemlist={[
                { 'url': '/myshoppingcart/', 'title': t('niki.b2b.cart.shoppingcart') },
                { 'url': `/thankyou`, 'title': t('niki.b2b.cartflow.ordercomplete') }
            ]} />
            <div className="thankyou_content">
                <h1>{t('niki.b2b.cartflow.thankyouh1')}!</h1>
                {orderNumber.length === 0
                    ? <LoadableImage src={''} alt={''} height={'270px'} styleContainer={{borderRadius: 8, marginBottom: 20 }} />
                    : orderNumber.length === orderIDs.length && orderNumber.sort((a, b) => a.order_number - b.order_number).map(el => {
                        return (
                            <div className="thankyou_red" key={el.order_id}>
                                <div className="thankyou_red__header">{t('niki.b2b.product.order')} {el.order_number > 0 && `#${el.order_number}`}</div>
                                <div className="thankyou_red__text">{t('niki.b2b.cartflow.redtext1')}.<br />{t('niki.b2b.cartflow.redtext2')}</div>
                                <button
                                    className="thankyou_red__button"
                                    data-uuid={el.order_id}
                                    onClick={() => OpenPDF(el.order_id)}
                                ><Printer />{t('niki.b2b.feedback.printreceipt')}</button>
                            </div>
                        )
                    })
                }
                <div className="other_account_options">{t('niki.b2b.feedback.otheroptons')}</div>
                <ul className="thankyou_account_buttons">
                    <li><NavLink to="/account/"><User />{t('niki.b2b.menu.myaccount')}</NavLink></li>
                    <li><NavLink to="/orders/"><Orders />{t('niki.b2b.account.orders')}</NavLink></li>
                    <li><NavLink to="/shoppingcarts/"><ShoppingCarts />{t('niki.b2b.cart.shoppingcarts')} {numberCarts > 0 && <span className="alert">{numberCarts}</span>}</NavLink></li>
                </ul>
                <div className="your_designated_support_team">{t('niki.b2b.account.supportteam')}</div>
                <TwoContacts />
            </div>
        </>
    )
}

export const ThankYou = () => {
    const [searchParams] = useSearchParams()
    const ids = searchParams.get('orderIds').split(',')
    return (
        <div className="content">
            <ThankYouContent orderIDs={ids} />
        </div>
    )
}