import React, { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom'
import { t } from "i18next"
import { bookletsTypeID, currentMainColor } from '../config.js'
import { getRequestOptions } from '../helpers/apiHelper'
import { ShowLoader, HideLoader } from "../functions"

import { BreadCrumbs } from "../components/BreadCrumbs"
import LoadableImage from "../components/LoadableImage/LoadableImage"

const FormatDate = localDate => {
    const fullDate = new Date(localDate)
    return `${String(fullDate.getDate()).padStart(2, '0')}.${String(fullDate.getMonth() + 1).padStart(2, '0')}.${fullDate.getFullYear()}`
}

export const PromotionalBooklets = () => {

    const navigate = useNavigate()
    const [booklets, setBooklets] = useState([])
    const [currentBooklet, setCurrentBooklet] = useState([])
    const [isCurrentBooklet, setIsCurrentBooklet] = useState(true)

    const BookletClick = (event, e) => { 
        document.querySelector('.promotional_booklets__list li.current').classList.remove('current')
        event.target.classList.add('current')
        setCurrentBooklet(e)
    }

    useEffect(()=>{
        ShowLoader()
        // fetch(`${process.env.REACT_APP_API_DOMAIN}/catalogs?include=creator,modifier,status,group,company,tasks,attachment`,requestOptions)
        fetch(`${process.env.REACT_APP_API_DOMAIN}/marketing-campaigns?include=attachments,items&search=typeableType.id:${bookletsTypeID}`,getRequestOptions())
        .then(response => response.json())
        .then(json => {
            setBooklets([])
            let currentAssigned = false
            const data = json.data || []
            data.sort((a, b) => {
                return new Date(a.created_at) - new Date(b.created_at);
            }).reverse()
            data.length > 0 && data.forEach((e,i) => {
                let pdfPath = ''
                let thumbPath = ''
                if (e.attachments && e.attachments.data && e.attachments.data.length > 0) {
                    e.attachments.data.reverse().forEach(el => {
                        if (el.extension === 'pdf') {
                            pdfPath = el?.links[0]?.url || ''
                        } else {
                            thumbPath = el?.links[0]?.url || ''
                        } 

                    })
                }
                const getProductsIdArray = (items) => {
                    return items.map(item => item.id)
                }
                const tempObj = {
                    id: e.id,
                    title: e.matchcode,
                    pdf: pdfPath,
                    image: thumbPath,
                    productsIdArray: e.items?.data?.length ? getProductsIdArray(e.items.data) : [],
                    from: FormatDate(e.start_at),
                    to: FormatDate(e.finish_at),
                }
                if (thumbPath.length > 0) {
                    setBooklets(prevState => ([...prevState, tempObj]))
                    if (!currentAssigned) {
                        setCurrentBooklet(tempObj)
                        currentAssigned = true
                    }
                }
            })
            setIsCurrentBooklet(false)
        })
        .catch(error => {
            setIsCurrentBooklet(false)
            console.error(error)
        })
        .finally(() => HideLoader())
    },[])

    const onBookletView = () => {
        localStorage.setItem('booklet.data', JSON.stringify(currentBooklet))
        navigate(`/all-products/?booklet=${currentBooklet.id}`)
    }

    return (
        <div className="content">
            <BreadCrumbs itemlist={[{'url':'/marketing/','title':t('niki.b2b.menu.marketing')},{'url':'/promotional-booklets/','title':t('niki.b2b.marketing.booklets')}]} />
            <div className="product_catalog__content">
                <h1 className="marginbottom20">{t('niki.b2b.marketing.booklets')}</h1>

                {(currentBooklet.image || isCurrentBooklet) && <div className="promotional_booklets__single_big">
                    <a href={currentBooklet.pdf} target="_blank" rel="noopener noreferrer">
                        {/* <img src={currentBooklet.image} alt="Promotional Booklets" loading="lazy" /> */}
                        <LoadableImage src={currentBooklet.image} alt={t('niki.b2b.marketing.booklets')} height={'310px'} styleContainer={{ display: 'flex', alignItems: 'center'}} styleImg={{ border: `1px solid ${currentMainColor}` , borderRadius: 10 }} />
                    </a>
                    <div>{t('niki.b2b.promotions.current')}</div>
                    <div className="promotional_booklets__single_big__dates">{currentBooklet.from} - {currentBooklet.to}</div>
                </div>}

                <h2>{t('niki.b2b.promotions.currentoffers')}</h2>
                <div className="marginbottom20">{t('niki.b2b.promotions.text')}</div>
                <button className="btn btn-info marginbottom40" onClick={onBookletView} disabled={currentBooklet?.productsIdArray?.length === 0}>
                    {t('niki.b2b.promotions.view')}
                </button>

                <h2 className="center marginbottom20">{t('niki.b2b.promotions.all')}</h2>
                <ul className="promotional_booklets__list">
                    {booklets.length > 0 && booklets.map((e,i) =>
                        <li
                            className={i === 0 ? 'current' : null}
                            onClick={(event) => BookletClick(event, e)}
                            key={`promobooklets${i}`}
                        >
                            <div className="promotional_booklets__list__img">{e.image &&
                                <LoadableImage src={e.image} alt={e.title} height={'150px'} />
                            }</div>
                            <div className="promotional_booklets__list__dates">{e.from} - {e.to}</div></li>
                    )}
                </ul>

            </div>
        </div>
    )
}