import { createSlice } from "@reduxjs/toolkit"

const filterSlice = createSlice({
    name: "filters",
    initialState: {
        status: false,
        selectedBrands: '',
        selectedCategories: '',
        selectedCountries: '',
        selectedFamilies: '',
        selectedKinds: '',
        sortType: 'all',
    },
    reducers: {
        setFilterStatus(state,action) {
            state.status = action.payload
        },
        setSelectedBrands(state,action) {
            state.selectedBrands = action.payload
        },
        setSelectedCategories(state,action) {
            state.selectedCategories = action.payload
        },
        setSelectedCountries(state,action) {
            state.selectedCountries = action.payload
        },
        setSelectedFamilies(state,action) {
            state.selectedFamilies = action.payload
        },
        setSelectedKinds(state,action) {
            state.selectedKinds = action.payload
        },
        setSortType(state,action) {
            state.sortType = action.payload
        },
    }
})

export default filterSlice.reducer
export const {setFilterStatus, setSelectedBrands, setSelectedCategories, setSelectedCountries, setSelectedFamilies, setSelectedKinds, setSortType} = filterSlice.actions