import React, { useEffect, useState } from "react"
import { t } from "i18next"

import { FixCompanyName, GetGlobalBannerData, scrollTop } from "../functions.js"
import { getRequestOptions } from '../helpers/apiHelper'
import { companyName } from "../config.js"

import { BreadCrumbs } from "../components/BreadCrumbs"
import { TwoContacts } from "../components/TwoContacts"
import LoadableImage from "../components/LoadableImage/LoadableImage"

import './../assets/css/staticpages.css'

const ShowLoader = () => document.querySelector('.loader').classList.add('active')
const HideLoader = () => document.querySelector('.loader').classList.remove('active')

const GetContactsData = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/entity-accounts/${localStorage.getItem('account.entity.id')}/company-contacts`,getRequestOptions())
    const json = await response.json()
    return json
}

const initialStateContacts = { address: '', email: '', telephone: '', fax: ''}

export const Contacts = () => {

    const [contacts,setContacts] = useState(initialStateContacts)
    const [pageImage,setPageImage] = useState([])

    useEffect(()=>{
        ShowLoader()
        scrollTop()
        GetContactsData()
        .then(json => {
            json.data && setContacts({
                address: json.data.address_1,
                email: json.data.email,
                telephone: json.data.telephone,
                fax: json.data.fax,
            })
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
    }, [])
    
    useEffect(() => {
        const data = JSON.parse(localStorage.getItem('banners_data'))
        // e.matchcode === 'B2B - Contacts - Image' 
        data && data.forEach(e => e.content_subtype_id === '21P0praYMaJGEYLx' && e.contentType?.data?.real_id === 5 && setPageImage(GetGlobalBannerData(e)))
    },[])

    return (
        <div className="content">
            <BreadCrumbs itemlist={[{'url':'/contact/','title':t('niki.b2b.contacts.us')}]} />
            <div className="contacts_content">
                <h1>{t('niki.b2b.contact.h1')} {FixCompanyName(companyName)}</h1>
                <LoadableImage src={pageImage[0]?.image} alt={pageImage[0]?.title} height={'310px'}/>
                {/* <img src={pageImage[0]?.image} alt={pageImage[0]?.title} className="contacts_image" loading="lazy" /> */}

                <div className={["your_designated_support_team", "margintop25"].join(' ')}>{t('niki.b2b.account.supportteam')}</div>
                <TwoContacts />

                <div className="contacts_header">{t('niki.b2b.contacts.contacts')}</div>
                
                <div className="contacts_grey">
                    <div className="contacts_header">{t('niki.b2b.contact.officewarehouse')}</div>
                    <hr />
                    <div className="contacts_grey__columns">
                        <strong>{t('niki.b2b.contact.tel')}:</strong>
                        <div><a href={`tel:${contacts.telephone}`}>{contacts.telephone}</a></div>
                        <strong>{t('niki.b2b.contact.fax')}:</strong>
                        <div>{contacts.fax}</div>
                        <strong>{t('niki.b2b.login.email')}:</strong>
                        <div><a href={`mailto:${contacts.email}`}>{contacts.email}</a></div>
                    </div>
                    <hr />
                    <div className="contacts_header marginbottom10">{t('niki.b2b.contact.address')}</div>
                    <div className="marginbottom20">{contacts.address}</div>
                    <div><a href="https://goo.gl/maps/cRcMEpBExRqsUvmXA" target="_blank" rel="noopener noreferrer nofollow" className="btn btn-block">{t('niki.b2b.contact.addressmap')}</a></div>
                </div>

            </div>
        </div>
    )
}