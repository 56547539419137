import { createSlice } from "@reduxjs/toolkit"

const updateSummCart = cartContent => {
    let summ = 0
    cartContent.forEach(element => summ += element.qty*element.price)
    return summ
}
const updateVatCart = cartContent => {
    let vat = 0
    cartContent.forEach(element => vat += element.qty*element.vat)
    return vat
}
const updateSummOldCart = cartContent => {
    let summ = 0
    cartContent.forEach(element => summ += element.qty*element.priceold)
    return summ
}
const updateProductIDs = cartContent => {
    let productIDs = []
    cartContent.forEach(element => productIDs.push(element.productid))
    return productIDs
}
const updateProductsQTYs = cartContent => {
    let productQTYs = {}
    cartContent.forEach(element => productQTYs[element.productid] = element.qty)
    return productQTYs
}
const updateProductSumms = cartContent => {
    let productSumms = {}
    cartContent.forEach(element => productSumms[element.productid] = parseInt(element.qty)*parseFloat(element.price))
    return productSumms
}
const udpateCartDetailsIDs = cartContent => {
    let cartDetailsIDs = {}
    cartContent.forEach(element => cartDetailsIDs[element.productid] = element.id)
    return cartDetailsIDs
}
const updateCartProductData = cartContent => {
    let cartProductData = {}
    cartContent.forEach(element => {
        cartProductData[element.productid] = {
            name: element.name,
            itemid: element.productitemid,
            qtyinbox: element.qtyinbox,
            unitcode: element.unitcode,
            image: element.image,
        }
    })
    return cartProductData
}
const UpdateCartData = state => {
    state.summ = updateSummCart(state.cartContent)
    state.summold = updateSummOldCart(state.cartContent)
    state.vat = updateVatCart(state.cartContent)
    state.productIDs = updateProductIDs(state.cartContent)
    state.productQTYs = updateProductsQTYs(state.cartContent)
    state.productSumms = updateProductSumms(state.cartContent)
    state.cartDetailsIDs = udpateCartDetailsIDs(state.cartContent)
    state.cartProductData = updateCartProductData(state.cartContent)
}

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        id: '',
        idreal: '',
        name: '',
        count: 0,
        summ: 0,
        summold: 0,
        vat: 0,
        status: 'notloaded',
        productIDs: [],
        productQTYs: {},
        productSumms: {},
        cartDetailsIDs: {},
        cartProductData: {},
        cartContent: [],
        quickAddProductCount: 0,
        uuidCart: '',
        productIdOpenCart: '',
    },
    reducers: {
        addCartProduct(state, action) {
            state.count += 1
            state.cartContent.push(action.payload)
            UpdateCartData(state)
        },
        updateCartProduct(state, action) {
            state.cartContent.forEach(element => {
                if (element.productid === action.payload.productid) element.qty = action.payload.qty
            })
            UpdateCartData(state)
        },
        deleteCartProduct(state,action) {
            state.count -= 1
            state.cartContent = state.cartContent.filter(element => element.id !== action.payload)
            UpdateCartData(state)
        },
        setIsLoaded(state, action) {
            state.status = action.payload ? action.payload : 'loaded'
        },
        changeQuickAddProductCount(state,action) {
            state.quickAddProductCount = action.payload
        },
        clearCartContent(state) {
            state.count = 0
            state.summ = 0
            state.summold = 0
            state.vat = 0
            state.status = 'notloaded'
            state.productIDs = []
            state.productQTYs = {}
            state.productSumms = {}
            state.cartDetailsIDs = {}
            state.cartContent = []
            state.cartProductData = {}
        },
        clearCartIdName(state) {
            state.id = ''
            state.idreal = ''
            state.name = ''
        },
        setCartHeaders(state,action) {
            state.id = action.payload.id
            state.idreal = action.payload.idreal
            state.name = action.payload.name
        },
        setUuidCart(state,action) {
            state.uuidCart = action.payload
        },
        setProductIdOpenCart(state,action) {
            state.productIdOpenCart = action.payload
        },
    }
})


export default cartSlice.reducer
export const {addCartProduct,updateCartProduct,deleteCartProduct,setIsLoaded,changeQuickAddProductCount,clearCartContent,setCartHeaders,clearCartIdName,setUuidCart,setProductIdOpenCart} = cartSlice.actions