import { instantMeiliSearch } from "@meilisearch/instant-meilisearch"
import { ArrayUniqe } from "./functions"
import { getBanners } from './helpers/apiHelper'
import favGenerix from './assets/favicon/favIconGenerix.png'
import favMonolith from './assets/favicon/favicon.ico'

const siteDomain = window.location.hostname

const xCompanyIDs = {
    'localhost': '2',
    'mb2b.niki24.xyz': '2',
    'mb2b-mcy.niki24.xyz': '3',
    'mb2b-muk.niki24.xyz': '2',
    'mb2b-mib.niki24.xyz': '1',
    'mb2b.monolith-pt.com': '1',
    'mb2b.monolith-uk.com': '2',
    'mb2b.monolith-cy.com': '3',
    'mb2b-generix.niki24.pt': '1',
    'mb2b-demo.niki24.pt': '1',
}

const companyNames = {
    'localhost': 'Monolith United Kingdom',
    'setup.niki24.xyz': 'Monolith United Kingdom',
    'mb2b-mcy.niki24.xyz': 'Monolith Cyprus',
    'mb2b-muk.niki24.xyz': 'Monolith United Kingdom',
    'mb2b-mib.niki24.xyz': 'Monolith Ibérica',
    'mb2b.monolith-pt.com': 'Monolith Ibérica',
    'mb2b.monolith-uk.com': 'Monolith United Kingdom',
    'mb2b.monolith-cy.com': 'Monolith Cyprus',
    'mb2b-generix.niki24.pt': 'GCP - Generix Group Portugal',
    'mb2b-demo.niki24.pt': 'Demo Company',
}

const companyXTenants = {
    'localhost': 'monolith',
    'setup.niki24.xyz': 'monolith',
    'mb2b-mcy.niki24.xyz': 'monolith',
    'mb2b-muk.niki24.xyz': 'monolith',
    'mb2b-mib.niki24.xyz': 'monolith',
    'mb2b.monolith-pt.com': 'monolith',
    'mb2b.monolith-uk.com': 'monolith',
    'mb2b.monolith-cy.com': 'monolith',
    'mb2b-generix.niki24.pt': 'generix',
    'mb2b-demo.niki24.pt': 'demo',
}

const companySuppliers = {
    'localhost': 'Monolith UK',
    'setup.niki24.xyz': 'Monolith UK',
    'mb2b-mcy.niki24.xyz': 'Monolith Cyprus',
    'mb2b-muk.niki24.xyz': 'Monolith United Kingdom',
    'mb2b-mib.niki24.xyz': 'Monolith Ibérica',
    'mb2b.monolith-pt.com': 'Monolith Ibérica',
    'mb2b.monolith-uk.com': 'Monolith UK',
    'mb2b.monolith-cy.com': 'Monolith CY',
    'mb2b-generix.niki24.pt': 'Generix Group Portugal B2B Webshop',
    'mb2b-demo.niki24.pt': 'Demo Company B2B Webshop',
}

const companyUrlAddresses = {
    'localhost': "https://monolith-uk.com/new-customer-application-form/",
    'setup.niki24.xyz': "https://monolith-uk.com/new-customer-application-form/",
    'mb2b-mcy.niki24.xyz': 'https://monolith-cy.com/',
    'mb2b-muk.niki24.xyz': 'https://monolith-uk.com/new-customer-application-form/',
    'mb2b-mib.niki24.xyz': 'https://monolith-pt.com/',
    'mb2b.monolith-pt.com': "https://monolith-pt.com/",
    'mb2b.monolith-uk.com': "https://monolith-uk.com/new-customer-application-form/",
    'mb2b.monolith-cy.com': "https://monolith-cy.com/",
    'mb2b-generix.niki24.pt': "https://b2b-generix.niki24.pt/",
    'mb2b-demo.niki24.pt': "https://b2b-demo.niki24.pt/",
}

const companyCountryShorts = {
    'localhost': 'gb',
    'setup.niki24.xyz': 'gb',
    'mb2b-mcy.niki24.xyz': 'gb',
    'mb2b-muk.niki24.xyz': 'cy',
    'mb2b-mib.niki24.xyz': 'pt',
    'mb2b.monolith-pt.com': 'pt',
    'mb2b.monolith-uk.com': 'gb',
    'mb2b.monolith-cy.com': 'cy',
    'mb2b-generix.niki24.pt': 'pt',
    'mb2b-demo.niki24.pt': 'pt',
}

const pricelineIDsRecommendeds = {
    'localhost': '0KkzrXn5gamP2ypM',
    'setup.niki24.xyz': '0KkzrXn5gamP2ypM',
    'mb2b.monolith-pt.com': 'O5D1YKnGOndeJ9qv',
    'mb2b.monolith-uk.com': '0KkzrXn5gamP2ypM',
    'mb2b.monolith-cy.com': '',
    'mb2b-generix.niki24.pt': '0KkzrXn5gamP2ypM',
    'mb2b-demo.niki24.pt': '0KkzrXn5gamP2ypM',
}

const bookletsTypeIDs = {
    'localhost': '38',
    'setup.niki24.xyz': '38',
    'mb2b-mcy.niki24.xyz': '38',
    'mb2b-muk.niki24.xyz': '38',
    'mb2b-mib.niki24.xyz': '38',
    'mb2b.monolith-pt.com': '38',
    'mb2b.monolith-uk.com': '38',
    'mb2b.monolith-cy.com': '38',
    'mb2b-generix.niki24.pt': '38',
    'mb2b-demo.niki24.pt': '38',
}

const catalogsTypeIDs = {
    'localhost': '39',
    'setup.niki24.xyz': '39',
    'mb2b-mcy.niki24.xyz': '39',
    'mb2b-muk.niki24.xyz': '39',
    'mb2b-mib.niki24.xyz': '39',
    'mb2b.monolith-pt.com': '39',
    'mb2b.monolith-uk.com': '39',
    'mb2b.monolith-cy.com': '39',
    'mb2b-generix.niki24.pt': '39',
    'mb2b-demo.niki24.pt': '39',
}

const feedbackPolicyIDs = {
    'localhost': 4,
    'setup.niki24.xyz': 4,
    'mb2b-mcy.niki24.xyz': 4,
    'mb2b-muk.niki24.xyz': 4,
    'mb2b-mib.niki24.xyz': 4,
    'mb2b.monolith-pt.com': 4,
    'mb2b.monolith-uk.com': 4,
    'mb2b.monolith-cy.com': 4,
    'mb2b-generix.niki24.pt': 4,
    'mb2b-demo.niki24.pt': 4,
}
const companyTenants = {
    'localhost': 'localhost',
    'setup.niki24.xyz': 'monolith',
    'mb2b-mcy.niki24.xyz': 'monolith',
    'mb2b-muk.niki24.xyz': 'monolith',
    'mb2b-mib.niki24.xyz': 'monolith',
    'mb2b.monolith-pt.com': 'monolith',
    'mb2b.monolith-uk.com': 'monolith',
    'mb2b.monolith-cy.com': 'monolith',
    'mb2b-generix.niki24.pt': 'generix',
    'mb2b-demo.niki24.pt': 'demo',
}

const companysGoogleTagId = {
    'localhost': 'G-PNDVYWSCLH',
    'setup.niki24.xyz': 'G-PNDVYWSCLH',
    'mb2b.monolith-pt.com': 'G-PNDVYWSCLH',
    'mb2b.monolith-uk.com': 'G-PNDVYWSCLH',
    'mb2b.monolith-cy.com': 'G-NR7WFC9EKC',
}

const companysClarityId = {
    'localhost': 'dixwjll684',
    // 'localhost': null,
    'setup.niki24.xyz': 'dixwjll684',
    'mb2b.monolith-pt.com': 'ftow3i0fgr',
    'mb2b.monolith-uk.com': 'ftovhfw6cm',
    'mb2b.monolith-cy.com': 'ftoz8sp5jo',
}

export const companyClarityId = companysClarityId[siteDomain];
export const companyGoogleTagId = companysGoogleTagId[siteDomain];

export const priceLineID = localStorage.getItem('priceline.id')
export const xCompanyID = xCompanyIDs[siteDomain]
export const companyName = companyNames[siteDomain]
export const companyCountryShort = companyCountryShorts[siteDomain]
export const pricelineIDRecommended = pricelineIDsRecommendeds[siteDomain]
export const bookletsTypeID = bookletsTypeIDs[siteDomain]
export const catalogsTypeID = catalogsTypeIDs[siteDomain]
export const feedbackPolicyID = feedbackPolicyIDs[siteDomain]
export const companySupplier = companySuppliers[siteDomain]
export const companyUrlAddress = companyUrlAddresses[siteDomain]
export const xTenant = companyXTenants[siteDomain]
export const companyTenant = companyTenants[siteDomain]

export const meilisearchUrl = process.env.REACT_APP_MEILISEARCH_URL
export const searchClient = instantMeiliSearch(
    meilisearchUrl,
    process.env.REACT_APP_MEILISEARCH_SECRET_KEY,
    {
        primaryKey: 'id',
        requestConfig: {
            headers: {
                'X-CompanyID': xCompanyID,
                'Accept-Language': localStorage.getItem('locale'),
                'X-Tenant': xTenant,
                'Accept': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_MEILISEARCH_SECRET_KEY}`,
            },
            method: 'GET'
        },
    }
)

export let segmentationInsert = ''
if (localStorage.getItem('account.segmentationids')) {
    const accountSegmentationIDs = localStorage.getItem('account.segmentationids')
    segmentationInsert = accountSegmentationIDs.length > 1 ? ArrayUniqe(accountSegmentationIDs.split(',')).join(',') : accountSegmentationIDs

    const segmentationIDs = accountSegmentationIDs.length > 1 ? accountSegmentationIDs.split(',') : [accountSegmentationIDs]
    try {
        getBanners(segmentationIDs.map(i => '&item_segmentations[]=' + i).join('')).then(json => {
        json.data && localStorage.setItem('banners_data', JSON.stringify(json.data))
    })} catch(error) {
        console.error(error)
    }
}

export const startFacetFilters = [
    `company_id=${xCompanyID}`,
    `itemPrices.${priceLineID}.priceline_id=${priceLineID}`,
    `itemStocks.source_warehouse_id=${localStorage.getItem('warehouse.id')}`,
    `itemSegmentations IN [${segmentationInsert}]`,
]

export const Languages = [
    {flag: 'gb', country: 'English', translateCountry: 'niki.b2b.english'},
    {flag: 'pt', country: 'Portugues', translateCountry: 'niki.b2b.portuguese' },
    {flag: 'de', country: 'Deutsche', translateCountry: 'niki.b2b.deutsche'},
    {flag: 'ru', country: 'Russian', translateCountry: 'niki.b2b.russian'},
    // {flag: 'es', country: 'Espanol', translateCountry: 'Espanol'},
    // {flag: 'it', country: 'Italiano', translateCountry: 'Italiano'},
    // {flag: 'fr', country: 'Francias', translateCountry: 'Francias'},
    // {flag: 'gr', country: 'GREEK', translateCountry: 'GREEK'},  
]
export const languagesArray = {
    'gb': 'niki.b2b.english',
    'pt': 'niki.b2b.portuguese',
    'de': 'niki.b2b.deutsche',
    'ru': 'niki.b2b.russian',
    // 'es': 'Espanol',
    // 'it': 'Italiano',
    // 'fr': 'Francias',
    // 'gr': 'GREEK', 
}


const companyColorMonolithred = {
    'localhost': '#9F0027',
    'setup.niki24.xyz': '#9F0027',
    'mb2b-mcy.niki24.xyz': '#9F0027',
    'mb2b-muk.niki24.xyz': '#9F0027',
    'mb2b-mib.niki24.xyz': '#9F0027',
    'mb2b.monolith-pt.com': '#9F0027',
    'mb2b.monolith-uk.com': '#9F0027',
    'mb2b.monolith-cy.com': '#9F0027',
    'mb2b-generix.niki24.pt': '#055780',
    'mb2b-demo.niki24.pt': '#055780',
};

const companyColorMonolithmain = {
    'localhost': '#fceaea',
    'setup.niki24.xyz': '#fceaea',
    'mb2b-mcy.niki24.xyz': '#fceaea',
    'mb2b-muk.niki24.xyz': '#fceaea',
    'mb2b-mib.niki24.xyz': '#fceaea',
    'mb2b.monolith-pt.com': '#fceaea',
    'mb2b.monolith-uk.com': '#fceaea',
    'mb2b.monolith-cy.com': '#fceaea',
    'mb2b-generix.niki24.pt': '#b1dcf1',
    'mb2b-demo.niki24.pt': '#b1dcf1',
};
export const currentMainColor = companyColorMonolithred[siteDomain]
export const currentLigthMainColor = companyColorMonolithmain[siteDomain]
  
document.documentElement.style.setProperty('--maincolor', currentMainColor);
document.documentElement.style.setProperty('--ligthmain', currentLigthMainColor);

const titleElement = document.querySelector('title');
titleElement.textContent = companyTenants[siteDomain];

const faviconElement = document.querySelector('link[rel="icon"]');
if (siteDomain === 'mb2b-generix.niki24.pt') {
    if (faviconElement) {
        faviconElement.href = favGenerix
    }
} else {
    faviconElement.href = favMonolith
}