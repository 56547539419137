import React, { useRef, useState } from "react"
import { Languages, languagesArray } from '../config.js'
import { t } from "i18next"
import { LangFix, returnLangForFlag } from '../functions'
import "../assets/css/login.css"

export const LangSelector = () => {
    const lang = LangFix(localStorage.getItem('locale'))
    const LangSelectorEl = useRef(null)
    const [isOpenLang,setOpenLang] = useState(false)
    const [selectedLang,setSelectedLang] = useState({
        flag: returnLangForFlag(lang),
        translateCountry: languagesArray[returnLangForFlag(lang)],
    })
   
    const SelectLang = e => {
        if (e.target.dataset.flag === localStorage.getItem('locale')) {
            LangSelectorEl.current.classList.remove('active')
        } else {
            setSelectedLang({
                flag: e.target.dataset.flag,
                translateCountry: e.target.dataset.translatecountry
            })
            LangSelectorEl.current.classList.remove('active')
            localStorage.setItem('locale', e.target.dataset.flag)
            localStorage.setItem('locale.full', e.target.dataset.country)
            document.location.reload()
        }
    }
    const ShowLangList = () => {
        LangSelectorEl.current.classList.add('active')
    }
    function closeModalOnClickOutside(event) {
        const modal = document.getElementById('lang_table')
        const lang = document.getElementById('lang')
        if (event.target === lang) {
            setOpenLang(true)
        } else if (event.target !== modal) {
            isOpenLang && LangSelectorEl.current.classList.remove('active')
            setOpenLang(false)
        }
    }
    document.addEventListener('click', closeModalOnClickOutside)

    return (
        <div className="lang_selector" ref={LangSelectorEl}>
            <div className="lang_selector__current" id='lang' onClick={ShowLangList}>
                <span className={['lang_selector__flag', `flag-icon-${selectedLang.flag}`].join(' ')}></span>
                {t(selectedLang.translateCountry)}
            </div>
            <ul className="lang_selector__list" id='lang_table'>
            {Languages.map((e,i)=>
                <li
                    key={`language${i}`}
                    onClick={SelectLang}
                    data-flag={e.flag}
                    data-country={e.country}
                    data-translatecountry={e.translateCountry}
                    className={e.flag === selectedLang.flag ? 'current' : 'regular'}
                >
                    <span className={['lang_selector__flag',`flag-icon-${e.flag}`].join(' ')}></span>{t(e.translateCountry)}
                </li>)}
            </ul>
        </div>
    )
}