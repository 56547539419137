import './LoadingDots.css'

const LoadingDots = () => {
    return (
        <div className="loadingDots">
            <div className="loadingDot"></div>
            <div className="loadingDot"></div>
            <div className="loadingDot"></div>
        </div>
    )
}

export default LoadingDots;