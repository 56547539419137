import React, { useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import { t } from "i18next"
import { getRequestOptions } from '../helpers/apiHelper'
import { ShowLoader, HideLoader } from '../functions'
import { feedbackPolicyID } from '../config.js'

import { BreadCrumbs } from "../components/BreadCrumbs"
import { TwoContacts } from "../components/TwoContacts"
import { AlertPopup } from "../components/AlertPopup.jsx"

import './../assets/css/feedback.css'

import { ReactComponent as Printer } from './../assets/svg/feedbackprinter.svg'
import { ReactComponent as SubmitFeedback } from './../assets/svg/gridfeedback.svg'
import { ReactComponent as Orders } from './../assets/svg/gridorders.svg'
import { ReactComponent as Account } from './../assets/svg/gridaccount.svg'
import { ReactComponent as Complaints } from './../assets/svg/feedbackcomplaints.svg'

const FormatDate = inputData => {
    const fullDate = new Date(inputData)
    return `${String(fullDate.getDate()).padStart(2,'0')}.${String(fullDate.getMonth() + 1).padStart(2, '0')}.${fullDate.getFullYear()}`
}

export const Feedback = () => {
    const [historyData, setHistoryData] = useState([])
    const [isFeedbackPolicyAlert, setIsFeedbackPolicyAlert] = useState(false)
    const [feedbackPolicyText,setFeedbackPolicyText] = useState('')

    const ExpandCollaapse = e => {
        const CurrenClass = `.feedback_history__li${e.target.dataset.id.replace(' ','_')}`
        document.querySelector(CurrenClass).classList.toggle('active')
    }

    useEffect(()=>{
        fetch(`${process.env.REACT_APP_API_DOMAIN}/privacy-policies?id=${feedbackPolicyID}`,getRequestOptions())
        .then(response => response.json())
        .then(json => {
            json.data && setFeedbackPolicyText(json.data.filter(e => e.real_id === feedbackPolicyID)[0].body)
        })
    }, [])
    
    useEffect(()=>{
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/feedbacks/?entity_account_id=${localStorage.getItem('account.entity.id')}&realKey=true&include=feedbackType,status`,getRequestOptions())
        .then(response => response.json())
        .then(json => {
            setHistoryData([])
            json.data && json.data.forEach(element => {
                const tempObj = {
                    id: element.id,
                    id_real: element.id_real,
                    processID: element.process_id,
                    processID_real: element.process_id_real,
                    orderID: element.document_id_real,
                    status: element.status && element.status.data && element.status.data.matchcode,
                    color: element.status && element.status.data && element.status.data.color,
                    order: element.document_id_real,
                    orderdate: FormatDate(element.created_at),
                    type: element.feedbackType && element.feedbackType.data && element.feedbackType.data.matchcode,
                    complaintdate: FormatDate(element.updated_at),
                }
                setHistoryData(prevState => ([...prevState, tempObj]))
            })
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())

    }, [])
    
    return (
        <div className="content">
            <BreadCrumbs itemlist={[{'url':'/account/','title':t('niki.b2b.menu.myaccount')},{'url':'/feedback/','title':t('niki.b2b.menu.feedback')}]} />
            <div className="feedback_content">
                <h1>{t('niki.b2b.menu.feedback')}</h1>
                <div className="feedback_grey">
                    <div className="feedback_grey__header">{t('niki.b2b.feedback.alert.title')}</div>
                    <div>{t('niki.b2b.feedback.alert.text1')} {t('niki.b2b.feedback.alert.text1')}</div>
                    <div className="feedback_grey__view_policy" onClick={() => setIsFeedbackPolicyAlert(true)}><Printer />{t('niki.b2b.feedback.alert.policy')}</div>
                </div>
                
                <h2>{t('niki.b2b.feedback.history')}</h2>
                <ul className="feedback_history">
                {historyData.map((e,i)=>
                    <li key={`feedbackhistory${i}`} className={[`feedback_history__li${e.id.replace(' ','_')}`,`feedback_history__li`].join(' ')}>
                        <div className="feedback_history__first">
                            <strong>{t('niki.b2b.number')}</strong>
                            <strong>{t('niki.b2b.process.h1')}</strong>
                            <strong>{t('niki.b2b.feedback.status')}</strong>
                            <span>{e.id_real}</span>
                            <span>{e.processID_real}</span>
                            <span><span className={[`feedback_history__first__status`].join(' ')} style={{background: e.color}}>{e.status}</span></span>
                            <button className="feedback_history__first__button" onClick={ExpandCollaapse} data-id={e.id}></button>
                        </div>
                        <div className="feedback_history__second">
                            <div className="feedback_history__second__type"><strong>{t('niki.b2b.feedback.type')}:</strong> {e.type}</div>
                            <div className="flex-spacebeetwen marginbottom5">
                                <div>{t('niki.b2b.product.order')}: {e.orderID}</div>
                                <div>{t('niki.b2b.debts.orderdate')}: {e.orderdate}</div>
                            </div>
                            <div className="flex-spacebeetwen marginbottom5">
                                <div>{t('niki.b2b.feedback.type')}: {e.type}</div>
                                <div>{t('niki.b2b.product.date')}: {e.complaintdate}</div>
                            </div>
                            <NavLink to={`/feedback-status/${e.id}/`} className="feedback_history__second__view_complaint"><Complaints />{t('niki.b2b.feedback.view')}</NavLink>
                        </div>
                    </li>
                )}
                </ul>

                <hr />
                
                <h2>{t('niki.b2b.feedback.accountoptions')}</h2>
                <ul className="feedback_grid">
                    <li><NavLink to="/submit-feedback/"><SubmitFeedback />{t('niki.b2b.feedback.submit')}</NavLink></li>
                    <li><NavLink to="/orders/"><Orders />{t('niki.b2b.account.orders')}</NavLink></li>
                    <li><NavLink to="/account/"><Account />{t('niki.b2b.menu.myaccount')}</NavLink></li>
                </ul>
                
                <div className="your_designated_support_team">{t('niki.b2b.account.supportteam')}</div>
                <TwoContacts />
            </div>
            {isFeedbackPolicyAlert && <AlertPopup isPadding={true} text={feedbackPolicyText} closeAlert={() => setIsFeedbackPolicyAlert(false)} /> }
        </div>
    )
}