import React, { useRef, useState }  from 'react'
import { useDispatch } from "react-redux"
import { NavLink } from 'react-router-dom'

import { PriceFormat, CapacityString, returnStockStatusText, LangFix, checkValueOrNonInteger } from '../../functions.js'
import { changeQuickAddProductCount } from "../../reduxToolkit/cartSlice"
import { priceLineID } from '../../config'


import '../../assets/css/shoppingcart.css'
import { ReactComponent as Brand } from '../../assets/svg/scbrand.svg'
import { ReactComponent as Box } from '../../assets/svg/scbox.svg'
import { ReactComponent as New } from '../../assets/svg/ppnew.svg'
import { ReactComponent as Booklet } from '../../assets/svg/ppbooklet.svg'
import { ReactComponent as OrdersHistory } from '../../assets/svg/scordershistory.svg'

const selectedLanguage = LangFix(localStorage.getItem('locale'))

export const QuickAddProductTemplate = ({ data }) => {

    const dispatch = useDispatch()

    const qtyEl = useRef(null)

    const ProductID = data.id
    const ProductImage = data.productImage
    const ProductName = data.productName
    const ProductBrand = data?.brand?.name ? data.brand.name : ''
    const ProductLink = `/productpage/${ProductID}/`
    const ProductQtyInBox = parseInt(data?.itemUnitBarcodeByUnitCodeBox?.coefficient) | 0
    const ProductAvailable = data.instock
    const ProductCountry = data.country ? data.country.iso_3166_2.toLowerCase() : null
    const ProductIsNew = data.is_new
    const ProductIsBooklet = data.is_booklet
    const ProductItemID = data.source_item_id

    const [qty,setQty] = useState(ProductQtyInBox)
    
    let ProductPrice = data?.itemPrices[priceLineID]?.unit_price || 0
    let ProductPriceOld = 0
    if (data.is_discount && data.itemDiscounts) {
        ProductPriceOld = ProductPrice
        ProductPrice = parseFloat(data?.itemDiscounts[priceLineID]?.unit_price)
    }

    const unitCodeTranslations = data.main_unit_code_translations[selectedLanguage] || data.capacity_unit_code

    const ProductCapacity = CapacityString(data.capacity, data.capacity_unit_code, ProductPrice)
    let { stockStatus, stockText } = returnStockStatusText(ProductAvailable, unitCodeTranslations)


    const inputChangeQtyProduct = e => {
        const value = e.target.value
        if (typeof value === "string" && !value) {
            setQty(0)
            return
        }
       
        if (value.includes('.') && value.slice(value.indexOf('.')).length > 3) return
        
        const inputValue = checkValueOrNonInteger(value, data.sale_unit_code)
        if (inputValue === '') return 

        const res = data.sale_unit_code === 'KG' ? inputValue : parseInt(inputValue)

        if (inputValue !== qty) {
            dispatch(changeQuickAddProductCount(res))
        }
        setQty(res)
    }

    const plusQtyProduct = () => {
        const sum = +qtyEl.current.value+ProductQtyInBox
        const resultSum = typeof sum === "string" ? parseFloat(sum) : sum
        const resPlus = Number.isInteger(resultSum) ? resultSum : Number(resultSum.toFixed(2))

        setQty(resPlus)
        dispatch(changeQuickAddProductCount(resPlus))
    }

    const minusQtyProduct = () => {
        const subtractionResult = +qtyEl.current.value - ProductQtyInBox
        const difference = Number.isInteger(subtractionResult) ? subtractionResult : Number(subtractionResult.toFixed(2))
       
        setQty(difference)
        dispatch(changeQuickAddProductCount(difference))
    }

    return (
        <div className="quick_add_product_data">
            <div>
                <div className="quick_add_product_data__image"><img src={ProductImage} alt={ProductName} loading="lazy" /></div>
                <div className="quick_add_product_data__item_id">{ProductItemID}</div>
                {ProductCountry && <div className={[`quick_add_product_data__country`,`flag-icon-${ProductCountry}`].join(' ')}></div>}
                {ProductIsNew && <New className="quick_add_product_data__is_new" />}
                {ProductIsBooklet && <Booklet className="quick_add_product_data__is_booklet" />}
            </div>
            <div>
                <NavLink to={ProductLink} className="quick_add_product_data__name" >{ProductName}</NavLink>
                <div className="quick_add_product_data__info"><Brand />{ProductBrand}</div>
                <div className="quick_add_product_data__info"><Box />{ProductQtyInBox} {unitCodeTranslations}</div>
                <div className={[`quick_add_product_data__stock`,stockStatus].join(' ')}><span></span>{stockText}</div>
                {ProductPriceOld > 0 && <div className="quick_add_product_data__discount">{Math.round((ProductPrice-ProductPriceOld)/ProductPriceOld*100)}%</div>}
            </div>
            <div>
                <span className="quick_add_product_data__price">{PriceFormat(ProductPrice)}</span>
                <span className="quick_add_product_data__neaar_price">{ProductCapacity}</span>
            </div>
            <div className="quick_add_product_data__buttons">
                <button className="quick_add_product_data__orders_history"><OrdersHistory /></button>
                <div className="quick_add_product_data__cart">
                    <button
                        className="quick_add_product_data__cart_button minus"
                        onClick={minusQtyProduct}
                    >-</button>
                    <input
                        className="quick_add_product_data__cart_input"
                        value={qty}
                        onChange={inputChangeQtyProduct} 
                        ref={qtyEl}
                    />
                    <button
                        className="quick_add_product_data__cart_button plus"
                        onClick={plusQtyProduct}
                    >+</button>
                </div>
            </div>
        </div>
    )
}