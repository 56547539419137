import { useState, useEffect, useRef } from 'react'
import { t } from 'i18next'
import { FormatDate, PriceFormat, GetHistoryData } from '../functions'
import { getRequestOptions } from '../helpers/apiHelper'

import './../assets/css/orderhistory.css'

import { ReactComponent as Hash } from './../assets/svg/orderpopuphash.svg'
import { ReactComponent as Box } from './../assets/svg/orderpopupbox.svg'

export const OrderHistoryPopup = ({matchcode, imgsrc, qty_in_box, sale_unit_code, item_id, product_id, setIsOpenHistoryProduct, ...props}) => {
    const popupEl = useRef(null)
    const currentUrl = window.location

    const [productHistory, setProductHistory] = useState([])
    const [prodHistory, setProdHistory] = useState([])
    const [loading, setLoading] = useState(false)
    const [isClose, setIsClose] = useState(false)
 
    useEffect(() => {
        setLoading(true)
        fetch(`${process.env.REACT_APP_API_DOMAIN}/items/${product_id}/sale-history?account_id=${localStorage.getItem('account.entity.id')}&limit=5`,getRequestOptions())
            .then(response => response.json())
            .then(json => {
                if (json.data.length > 0) {
                    setProductHistory(json.data)
                } else {
                    setProdHistory(GetHistoryData(product_id))
                }
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
                console.error(error)
            })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let timer;

        if (isClose && setIsOpenHistoryProduct) {
            clearTimeout(timer);
            timer = setTimeout(() => setIsOpenHistoryProduct(false), 400)
        }
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [isClose])

    useEffect(() => {
        loading && popupEl.current.classList.add('active')   
    }, [loading])

    const hideHistoryModal = () => {
        popupEl.current.classList.remove('active')
        currentUrl.pathname.includes('all-products') && document.querySelector(`.aside_filters__content__button__show_hide`).classList.remove('display_none')
        setIsClose(true)
    }

    const prodPrice = props.price
    const _prodHistory = prodHistory || props.history

    return (
        <div className={[`order_history_popup__wrapper`,`itemid${item_id}`].join(' ')} ref={popupEl}>
            <div className="order_history_popup">
                <div className="order_history_popup__title">{t('niki.b2b.menu.orderhistory')}</div>
                <div className="order_history_popup__prodinfo">
                    <div className="order_history_popup__prodinfo__image"><img src={imgsrc} alt={matchcode} /></div>
                    <div>
                        <div className="order_history_popup__prodinfo__name">{matchcode}</div>
                        <div className="order_history_popup__prodinfo__itemid_uni">
                            <div><Hash />{item_id}</div>
                            <div><Box />{qty_in_box} {sale_unit_code || props.uni}</div>
                        </div>
                    </div>
                </div>
                <table className="order_history_popup__table">
                    <thead>
                        <tr>
                            <th>{t('niki.b2b.product.date')}</th>
                            <th>{t('niki.b2b.product.order')} ID</th>
                            <th>{t('niki.b2b.product.quantity')}</th>
                            <th>{t('niki.b2b.cart.total')}</th>
                        </tr>
                    </thead>

                    {loading &&
                        <tbody>
                            <tr>
                                <td className={["content_placeholder", "order_history__content_placeholder"].join(' ')}></td>
                                <td className="content_placeholder"></td>
                                <td className="content_placeholder"></td>
                                <td className="content_placeholder"></td>
                            </tr>
                        </tbody>
                    }
                    
                    {productHistory.length > 0
                        ? <tbody>
                            {productHistory.map((e, i) =>
                                <tr key={`historytable${i}`}>
                                    <td>{e.created_at}</td>
                                    <td>{`${e.doc_header_key} - ${e.doc_header_serial} ${e.doc_header_id}`}</td>
                                    <td>{e.quantity}</td>
                                    <td>{PriceFormat(parseFloat(e.unit_price) * parseInt(e.quantity))}</td>
                                </tr>
                            )}
                        </tbody>
                        : <tbody>
                            {_prodHistory.map((e, i) =>
                                <tr key={`historytable${i}`}>
                                    <td>{FormatDate(e.created_at)}</td>
                                    <td>{e.document_serial_number}</td>
                                    <td>{e.quantity}</td>
                                    <td>{`${PriceFormat(parseInt(e.quantity)*parseFloat(prodPrice || e.unit_price))}`}</td>
                                </tr>
                            )}
                        </tbody>
                    }                   
                </table>
                <button className="order_history_popup__close" onClick={hideHistoryModal}></button>
            </div>
            <div className="order_history_popup__overlay" onClick={hideHistoryModal}></div>
        </div>
    )
}